import { notify } from "@kyvg/vue3-notification";
import Lang from "@/plugins/Lang";
export default class Module {
    constructor() { }
    notifyError(err) {
        notify({
            title: Lang.get("globals.default_error_title"),
            type: "error",
            text: err.getMessage(),
        });
        return err;
    }
}
