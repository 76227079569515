import defaultRequest from "@/api/DefaultRequest";
class AutoKitchen {
    constructor() {
        // .
    }
    addCollection(data) {
        return defaultRequest.post("auto-kitchen/backend/collections/", {
            data,
        });
    }
    getCollections() {
        return defaultRequest.get(`auto-kitchen/backend/collections/`);
    }
    getCollection(id) {
        return defaultRequest.get(`auto-kitchen/backend/collections/${id}`);
    }
    updateCollection(id, data) {
        const params = {
            _method: "PATCH",
            data,
        };
        return defaultRequest.post(`auto-kitchen/backend/collections/${id}`, params);
    }
    delete(id) {
        return defaultRequest.post(`auto-kitchen/backend/collections/${id}`, {
            _method: "DELETE",
        });
    }
}
export default new AutoKitchen();
