import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { key: 0 };
import { onMounted, ref, watch } from "vue";
import Planner from "@/service/Planner";
export default /*@__PURE__*/ _defineComponent({
    __name: 'QueueList',
    emits: ["change"],
    setup(__props, { emit: __emit }) {
        const emit = __emit;
        function showModal() {
            //
        }
        const count = ref(0);
        watch(Planner.queueCount, () => {
            count.value = Planner.queueCount.value;
        });
        onMounted(() => { });
        return (_ctx, _cache) => {
            return (count.value > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", {
                        class: "modal-queue-helper",
                        onClick: _withModifiers(showModal, ["prevent"])
                    }, [
                        _cache[0] || (_cache[0] = _createTextVNode(" Моделей в очереди: ")),
                        _createElementVNode("b", null, _toDisplayString(count.value), 1)
                    ])
                ]))
                : _createCommentVNode("", true);
        };
    }
});
