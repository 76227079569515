import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "row" };
const _hoisted_2 = { class: "col-lg-12" };
const _hoisted_3 = { class: "card" };
const _hoisted_4 = { class: "card-body" };
import UserDetail from "@/binaplan/components/admin/user/DetailContainer.vue";
import TitlePage from "@/binaplan/components/layouts/TitlePageContainer.vue";
import Lang from "@/plugins/Lang";
export default /*@__PURE__*/ _defineComponent({
    __name: 'DetailView',
    setup(__props) {
        const lang = {
            title: Lang.get("admin-user.title_detail_page"),
            preloader: Lang.get("globals.preloader_loading"),
            back_btn: Lang.get("admin-user.back_to_list"),
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createVNode(TitlePage, {
                    "back-button": lang.back_btn,
                    "back-url": "/admin/users",
                    "title-page": lang.title
                }, null, 8, ["back-button", "title-page"]),
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("div", _hoisted_3, [
                            _createElementVNode("div", _hoisted_4, [
                                (_openBlock(), _createBlock(_Suspense, null, {
                                    fallback: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(lang.preloader), 1)
                                    ]),
                                    default: _withCtx(() => [
                                        _createVNode(UserDetail)
                                    ]),
                                    _: 1
                                }))
                            ])
                        ])
                    ])
                ])
            ], 64));
        };
    }
});
