import api from "@/api/Api";
import router from "@/binaplan/router";
// import { AxiosResponse } from "axios";
class Auth {
    constructor() { }
    login(email, password) {
        const urn = "/auth/login";
        const data = { email, password };
        return api.httpRequest.post(urn, data);
    }
    logout() {
        const urn = "/auth/logout";
        return api.httpRequest.post(urn);
    }
    /**
     * Пока метод как и login
     * @param email
     * @param password
     * @param passwordConfirm
     * @param name
     */
    signup(email, password, passwordConfirm, name) {
        const urn = "/auth/signup";
        const data = { email, password, passwordConfirm, name };
        return api.httpRequest.post(urn, data);
    }
    isAuthorized() {
        return this.getToken().length > 0;
    }
    getToken() {
        return localStorage.getItem("token") ?? "";
    }
    getEmail() {
        return localStorage.getItem("email") ?? "";
    }
    showMenu() {
        return this.isAuthorized();
    }
    setToken() {
        api.createRequestHandler();
    }
    removeToken() {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
    }
    updateLocalStorage(responseServer, email) {
        if (responseServer.data.data &&
            typeof responseServer.data.data.accessToken !== "undefined") {
            localStorage.setItem("token", responseServer.data.data.accessToken);
            localStorage.setItem("email", email);
            const sessionBackUrl = sessionStorage.getItem("backUrl");
            return sessionBackUrl !== null ? sessionBackUrl : "/";
        }
        return null;
    }
    hideNonAuthSection() {
        const currentName = router.currentRoute.value.name;
        return currentName == "login" || currentName == "signup";
    }
}
export default new Auth();
