import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect } from "vue";
const _hoisted_1 = { class: "row" };
const _hoisted_2 = { class: "col-lg-6" };
const _hoisted_3 = { class: "mb-3" };
const _hoisted_4 = {
    for: "userName",
    class: "form-label"
};
const _hoisted_5 = {
    key: 0,
    class: "mb-3"
};
const _hoisted_6 = {
    for: "userEmail",
    class: "form-label"
};
const _hoisted_7 = {
    key: 1,
    class: "mb-3"
};
const _hoisted_8 = {
    for: "formFile",
    class: "form-label"
};
const _hoisted_9 = ["value"];
const _hoisted_10 = {
    key: 2,
    class: "mb-3"
};
const _hoisted_11 = ["value"];
const _hoisted_12 = { class: "mb-3" };
const _hoisted_13 = ["value"];
const _hoisted_14 = { class: "mb-3" };
import { ref, onMounted } from "vue";
import Lang from "@/plugins/Lang";
import UserHandler from "@/api/User";
import Company from "@/module/Company";
import User from "@/module/User";
import { notify } from "@kyvg/vue3-notification";
import PlannerBackend from "@/module/WebPlanner";
import Router from "@/binaplan/router";
import RequestUser from "@/api/User";
export default /*@__PURE__*/ _defineComponent({
    __name: 'DetailContainer',
    props: {
        mode: { type: String, default: "admin" },
    },
    setup(__props) {
        let routerId = Router.currentRoute.value.params.id;
        const userId = ref(+(typeof routerId === "undefined" ? 0 : routerId));
        const user = ref({});
        const groups = ref([]);
        const companies = ref([]);
        const plannerInstances = ref([]);
        const props = __props;
        const lang = ref({
            login: Lang.get("auth.title_signup_page"),
            btn_data_update: Lang.get("globals.data_update"),
            title_success: Lang.get("globals.default_success_title"),
            field_username: Lang.get("user.field_name"),
            field_email: Lang.get("user.field_email"),
            field_company: Lang.get("company.name"),
        });
        function updateUser() {
            UserHandler.updateUser(userId.value, user.value).then((res) => {
                notify({
                    title: lang.value.title_success,
                    type: "success",
                    text: res.getMessage(),
                });
                if (userId.value === 0) {
                    RequestUser.getMe().then((res) => {
                        if (res !== null) {
                            if (res.getType() === "success") {
                                sessionStorage.setItem("me", JSON.stringify(res.getResponse()));
                                window.location.reload();
                            }
                        }
                    });
                }
            });
        }
        async function setCompanies() {
            const companiesPromises = await Company.getList();
            if (companiesPromises !== null) {
                companies.value = companiesPromises.getResponse();
            }
        }
        async function setUser() {
            const userPromises = await User.getById(userId.value);
            if (userPromises !== null) {
                if (userPromises.getType() === "success") {
                    user.value = userPromises.getResponse();
                }
            }
        }
        async function setAccessGroups() {
            const groupsPromises = await User.getAccessGroups();
            if (groupsPromises !== null) {
                groups.value = groupsPromises.getResponse();
            }
        }
        async function setInstances() {
            const instancesPromises = await PlannerBackend.getInstances();
            if (instancesPromises !== null) {
                plannerInstances.value = instancesPromises.getResponse();
            }
        }
        onMounted(async () => {
            // await setCompanies();
            await setAccessGroups();
            await setUser();
            await setInstances();
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("label", _hoisted_4, _toDisplayString(lang.value.field_username) + ": ", 1),
                        _withDirectives(_createElementVNode("input", {
                            class: "form-control",
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((user.value.name) = $event)),
                            type: "text"
                        }, null, 512), [
                            [_vModelText, user.value.name]
                        ])
                    ]),
                    (__props.mode === 'admin')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createElementVNode("label", _hoisted_6, _toDisplayString(lang.value.field_email) + ": ", 1),
                            _withDirectives(_createElementVNode("input", {
                                class: "form-control",
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((user.value.email) = $event)),
                                type: "email"
                            }, null, 512), [
                                [_vModelText, user.value.email]
                            ])
                        ]))
                        : _createCommentVNode("", true),
                    (__props.mode === 'admin')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createElementVNode("label", _hoisted_8, _toDisplayString(lang.value.field_company) + ": ", 1),
                            _withDirectives(_createElementVNode("select", {
                                class: "form-select",
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((user.value.companyId) = $event))
                            }, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(companies.value, (company) => {
                                    return (_openBlock(), _createElementBlock("option", {
                                        value: company.id,
                                        key: company.id
                                    }, _toDisplayString(company.name), 9, _hoisted_9));
                                }), 128))
                            ], 512), [
                                [_vModelSelect, user.value.companyId]
                            ])
                        ]))
                        : _createCommentVNode("", true),
                    (__props.mode === 'admin')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _cache[6] || (_cache[6] = _createElementVNode("label", {
                                for: "formFile",
                                class: "form-label"
                            }, "Группы:", -1)),
                            _withDirectives(_createElementVNode("select", {
                                class: "form-select",
                                multiple: "",
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((user.value.groups) = $event))
                            }, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(groups.value, (group) => {
                                    return (_openBlock(), _createElementBlock("option", {
                                        value: group.id,
                                        key: group.id
                                    }, _toDisplayString(group.name), 9, _hoisted_11));
                                }), 128))
                            ], 512), [
                                [_vModelSelect, user.value.groups]
                            ])
                        ]))
                        : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_12, [
                        _cache[7] || (_cache[7] = _createElementVNode("label", {
                            for: "formFile",
                            class: "form-label"
                        }, "Экземпляр планнера:", -1)),
                        _withDirectives(_createElementVNode("select", {
                            class: "form-select",
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((user.value.instanceId) = $event))
                        }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(plannerInstances.value, (instance) => {
                                return (_openBlock(), _createElementBlock("option", {
                                    value: instance.id,
                                    key: instance.id
                                }, _toDisplayString(instance.name), 9, _hoisted_13));
                            }), 128))
                        ], 512), [
                            [_vModelSelect, user.value.instanceId]
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("button", {
                            onClick: _cache[5] || (_cache[5] = ($event) => (updateUser())),
                            class: "btn btn-info btn-sm"
                        }, _toDisplayString(lang.value.btn_data_update), 1)
                    ])
                ])
            ]));
        };
    }
});
