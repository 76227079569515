import defaultRequest from "@/api/DefaultRequest";
import User from "@/module/User";
class Handbook {
    constructor() { }
    getTypeList() {
        return defaultRequest.get("handbooks/types");
    }
    createType(params) {
        return defaultRequest.post("handbooks/types/add", params);
    }
    createCategoryKind(parentId, params) {
        const parent = parseInt(parentId) > 0 ? parentId + "/" : "";
        return defaultRequest.post("handbooks/kinds/" + parent + "add", params);
    }
    createProducer(params) {
        return defaultRequest.post("handbooks/producers/add", params);
    }
    getKindTree(parentId) {
        parentId = parentId.length == 0 ? 0 : parentId;
        return defaultRequest.get("handbooks/kinds/" + parentId + "/tree");
    }
    getGroupList(parentId) {
        return defaultRequest.get("handbooks/groups/" + (parseInt(parentId) === 0 ? "" : parentId));
    }
    getGroupTree(parentId) {
        return defaultRequest.get("handbooks/groups/" + parentId + "/tree");
    }
    getKindList(parentId) {
        return defaultRequest.get("handbooks/kinds/" + parentId);
    }
    // Brands
    getBrandList() {
        return defaultRequest.get("handbooks/brands/");
    }
    createBrand(params) {
        return defaultRequest.post("handbooks/brands/add", params);
    }
    getBrand(brandId) {
        return defaultRequest.get("handbooks/brands/" + brandId);
    }
    deleteBrand(brandId) {
        const params = { _method: "DELETE" };
        return defaultRequest.post("handbooks/brands/" + brandId, params);
    }
    updateBrand(brandId, params) {
        params._method = "PATCH";
        return defaultRequest.post("handbooks/brands/" + brandId, params);
    }
    // end Brands
    getProducerList() {
        return defaultRequest.get("handbooks/producers/");
    }
    getProducer(producerId) {
        return defaultRequest.get("handbooks/producers/" + producerId);
    }
    updateProducer(producerId, params) {
        params._method = "PATCH";
        return defaultRequest.post("handbooks/producers/" + producerId, params);
    }
    deleteProducer(producerId) {
        const params = { _method: "DELETE" };
        return defaultRequest.post("handbooks/producers/" + producerId, params);
    }
    createCategory(params) {
        return defaultRequest.post("handbooks/categories/add", params);
    }
    updateCategory(categoryId, params) {
        const paramsUpdate = params;
        paramsUpdate._method = "PATCH";
        return defaultRequest.post("handbooks/categories/" + categoryId, paramsUpdate);
    }
    getCategoryList(parentId) {
        return defaultRequest.get("handbooks/categories/?parentId=" + parentId);
    }
    getCategoryTree(parentId) {
        return defaultRequest.get("handbooks/categories/" + parentId + "/tree");
    }
    getCategoryById(id) {
        return defaultRequest.get("handbooks/categories/" + id);
    }
    createOwnHandbookItem(itemId, item) {
        return defaultRequest.post("handbooks/own/" + itemId + "/add", item);
    }
    createOwnHandbook(params, properties) {
        return defaultRequest.post("handbooks/own/add", {
            handbook: params,
            properties: properties,
        });
    }
    getOwnHandbookList() {
        return defaultRequest.get("handbooks/own");
    }
    getOwnHandbook(id, all = false) {
        return defaultRequest.get("handbooks/own/" + id + (all ? "?all" : ""));
    }
    getOwnHandbookValues(id) {
        return defaultRequest.get("handbooks/own/" + id + "/values");
    }
    getOwnHandbookColumns(id) {
        return defaultRequest.get("handbooks/own/" + id + "/columns");
    }
    getOwnHandbookItems(id) {
        return defaultRequest.get("handbooks/own/" + id + "/items");
    }
    getCharacteristicTypes() {
        return defaultRequest.get("handbooks/characteristics/types");
    }
    getPropertyTypes() {
        return defaultRequest.get("properties/types");
    }
    createDecor(data) {
        return defaultRequest.post("handbooks/decors/add", data);
    }
    createSurface(data) {
        return defaultRequest.post("handbooks/surfaces/add", data);
    }
    updateDecor(decorId, data) {
        const dataPatch = data;
        dataPatch._method = "PATCH";
        return defaultRequest.post("handbooks/decors/" + decorId, data);
    }
    getDecors() {
        return defaultRequest.get("handbooks/decors");
    }
    getDecor(decorId) {
        return defaultRequest.get("handbooks/decors/" + decorId);
    }
    deleteDecor(decorId) {
        const params = { _method: "DELETE" };
        return defaultRequest.post("handbooks/decors/" + decorId, params);
    }
    createTexture(formData) {
        return defaultRequest.uploadFile("handbooks/textures/add", formData);
    }
    getTexture(textureId = "0") {
        return defaultRequest.get("handbooks/textures/" + textureId);
    }
    getTextures(decorId = "0") {
        const filter = parseInt(decorId) > 0 ? "?decor_id=" + decorId : "";
        return defaultRequest.get("handbooks/textures" + filter);
    }
    createFinish(data) {
        return defaultRequest.post("handbooks/finishes/add", data);
    }
    updateFinish(finishId, data) {
        const dataPatch = data;
        dataPatch._method = "PATCH";
        return defaultRequest.post("handbooks/finishes/" + finishId, data);
    }
    getFinishes() {
        return defaultRequest.get("handbooks/finishes");
    }
    getSurfaces() {
        return defaultRequest.get("handbooks/surfaces");
    }
    getSurface(surfaceId) {
        return defaultRequest.get("handbooks/surfaces/" + surfaceId);
    }
    getFinish(finishId) {
        return defaultRequest.get("handbooks/finishes/" + finishId);
    }
    deleteFinish(finishId) {
        const params = { _method: "DELETE" };
        return defaultRequest.post("handbooks/finishes/" + finishId, params);
    }
    deleteSurface(surfaceId) {
        const params = { _method: "DELETE" };
        return defaultRequest.post("handbooks/surfaces/" + surfaceId, params);
    }
    deleteTexture(textureId, confirmDelete = false) {
        const params = {
            _method: "DELETE",
            confirm: confirmDelete,
        };
        return defaultRequest.post("handbooks/textures/" + textureId, params);
    }
    deleteDeepmap(deepmapId, confirmDelete = false) {
        const params = {
            _method: "DELETE",
            confirm: confirmDelete,
        };
        return defaultRequest.post("handbooks/deepmaps/" + deepmapId, params);
    }
    createDeepmap(formData) {
        return defaultRequest.uploadFile("handbooks/deepmaps/add", formData);
    }
    getDeepmaps(finishId = "0") {
        const filter = parseInt(finishId) > 0 ? "?finish_id=" + finishId : "";
        return defaultRequest.get("handbooks/deepmaps" + filter);
    }
    getMaterialList() {
        return defaultRequest.get("handbooks/materials");
    }
    getAnchorList(p = 1, q = "", isPlanner = false) {
        return defaultRequest.get("handbooks/anchors?p=" + p + "&q=" + q + "&planner=" + isPlanner);
    }
    createAnchor(name, sku, plus) {
        const userInstanceId = User.getMe().instanceId;
        return defaultRequest.post("handbooks/anchors", {
            name: name,
            plus: plus,
            sku: sku,
            instanceId: userInstanceId,
        });
    }
    createGroup(group) {
        return defaultRequest.post("handbooks/groups/add", group);
    }
}
export default new Handbook();
