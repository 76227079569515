import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "row" };
const _hoisted_2 = { class: "row mt-4" };
const _hoisted_3 = { class: "col-sm-12 mt-sm-0 mt-4" };
import CompanyList from "@/binaplan/components/admin/company/ListContainer.vue";
import TitlePage from "@/binaplan/components/layouts/TitlePageContainer.vue";
import Lang from "@/plugins/Lang";
export default /*@__PURE__*/ _defineComponent({
    __name: 'ListView',
    setup(__props) {
        const lang = {
            title: Lang.get("admin-company.list_title"),
            btn_add: Lang.get("admin-company.button_add"),
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createElementVNode("div", _hoisted_1, [
                    _createVNode(TitlePage, {
                        "back-button": lang.btn_add,
                        "back-url": "/admin/companies/create",
                        "title-page": lang.title
                    }, null, 8, ["back-button", "title-page"])
                ]),
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createVNode(CompanyList)
                    ])
                ])
            ], 64));
        };
    }
});
