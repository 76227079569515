import WSocket from "@/api/PlannerWSocket";
class PlannerWebSocketClient {
    constructor() {
        WSocket.createWebSocket();
    }
    getGeometries() {
        console.log("ЗАПРОС 1");
        return WSocket.sendRawData("eJxlkMuOo1YQhl9lxCqR2tEBbAO942Z8bHM3102Lm83BgG2uhtFI2eQdsslLjDTv0fNGAXdaURSdzamv/qr6q75iQdSga4m9fsXKoEiwVwyWdVI1X4prnOTYC9aW8XWidRNUzRQ2w20Woado8Sn6COU5gvGUXjH0mpn4KW/r9P/4WaZ8jHv/8/2vXygC/Pr+fXo/fv7+849PxUcNtaSoNY7Ta2JNMjhNk1O6vrTYa9nm+bcXrEo6VD9XwHCG+E/tGgf0EpsldZs3MyJesKxvphSXBFVSfUmGXRpKEVLRzrRGiCsI1rBobj4P1/AiDkYmrxTL2MgCx8vHlDeO597I/I3+ZIooj1GvW/qgAHFULgqvjxE68DuQuOzcM4355h6T+syWiqvPrIsvjCMTsZBIl8bHORQdldLaGJk5cm047laqJKOT/ttkrfUKD6mZSMoCBMrIPpSMnezZy2iyJx+jXs50Uj6yhMz3KHCVEWZXFGwNEG3l9WFgyEjaDAfXyCNSbz3HayKCKWLpkXpolYUEmG2lsfO05frOjrPEy8yyUMrzsDROwb85PZQ2bVjYA0Q9ikgbzbPkIwRz7Lk2CDYMCBw8n/hj8joowuRPkMmp383fPs9Rh4SShvx04nLXhZKF1LxGnmM0gbOc5sLs8++a0wxC6aJ/NCHJ5WGRX56zt7vOL/LaNz/72OPMbcJGus3t5n9I+MW0Q+vbTOoRyrS//OzpOfgAs1sIS470+V08nZkFQ2JpD5z1Y0tQwSG01w5nM97mci6tlH5T8vvdF9l7RFJ5crA608sTWOPpMgd1n1ksUXC33rLVh14sOG0p2zeRwV15Q0lvj0iLibDkYsBf6HJkikhjFJ1XVI2uOxiBRt6cG907nog3H2jZNtC0ZD9UYLGXbbsQ76jW9heeYM7aYcX0gs8DQXCX3ShYJn204M2B+45nT1WhEaa66qgy5IyVlI2eKUrjQRXafdwvdy24X+nqTA+3xePNZ/YwtoprNPDSLXd0RoAkZYLAJnVRNO7Ggl2M25EKTo6VnRLyHo0VLBI+VBDI0pjt+dA1lqNZquLC5NvoUFPWsN+623VVS2fmDV+re3i8i2fs29/gQXSv", false);
    }
    async getOriginalGeometries(furnId, index = -1) {
        return await WSocket.send("getOriginalGeometries", {
            id: furnId,
            index: index,
        });
    }
    searchMaterials(type, list) {
        //console.log({ type, list });
        return WSocket.send("searchMaterials", {
            type: type,
            list: list,
        });
    }
}
export default new PlannerWebSocketClient();
