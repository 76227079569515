import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "row" };
const _hoisted_2 = { class: "col-sm-12 mt-sm-0 mt-4" };
const _hoisted_3 = { class: "row" };
const _hoisted_4 = { class: "col-lg-12 mb-4" };
const _hoisted_5 = { class: "card small-card" };
const _hoisted_6 = { class: "card-body" };
const _hoisted_7 = { class: "list-group" };
const _hoisted_8 = { class: "list-group mb-4" };
const _hoisted_9 = { class: "list-group mb-4" };
const _hoisted_10 = { class: "list-group mb-4" };
const _hoisted_11 = { class: "list-group mb-4" };
import { ref } from "vue";
import { onMounted } from "vue";
import Lang from "@/plugins/Lang";
import TitlePage from "@/binaplan/components/layouts/TitlePageContainer.vue";
import PlannerWebSocketClient from "@/module/PlannerWebSocketClient";
export default /*@__PURE__*/ _defineComponent({
    __name: 'DashboardView',
    setup(__props) {
        //import DashboardContainer from "@/binaplan/components/admin/DashboardContainer.vue";
        onMounted(() => {
            console.log(1);
            PlannerWebSocketClient.getGeometries();
        });
        const lang = ref({
            title_page: Lang.get("company.field_name"),
            title_nomenclature: Lang.get("admin-nomenclature.section_title"),
            title_type_list: Lang.get("admin-nomenclature.type_list_title"),
            title_property_list: Lang.get("admin-nomenclature.type_property_list_title"),
            title_model: Lang.get("admin-model.section_title"),
            title_user: Lang.get("admin-user.section_title"),
            title_user_list: Lang.get("admin-user.list_title"),
            title_company: Lang.get("admin-company.section_title"),
            title_company_list: Lang.get("admin-company.list_title"),
            title_surface: Lang.get("handbooks.section_title_surface"),
            title_textures: Lang.get("handbooks.section_link_textures"),
        });
        return (_ctx, _cache) => {
            const _component_router_link = _resolveComponent("router-link");
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createVNode(TitlePage, {
                    "title-page": "Админ панель",
                    "back-button": "",
                    "back-url": ""
                }),
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("div", _hoisted_3, [
                            _createElementVNode("div", _hoisted_4, [
                                _createElementVNode("div", _hoisted_5, [
                                    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "card-header" }, [
                                        _createElementVNode("div", { class: "d-flex justify-content-between" }, [
                                            _createElementVNode("h5", null, "Синхронизация")
                                        ])
                                    ], -1)),
                                    _createElementVNode("div", _hoisted_6, [
                                        _createElementVNode("ul", _hoisted_7, [
                                            _createElementVNode("li", _hoisted_8, [
                                                _createVNode(_component_router_link, { to: "/admin/planner/instances" }, {
                                                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                                                        _createTextVNode(" Planner Instances ")
                                                    ])),
                                                    _: 1
                                                })
                                            ]),
                                            _createElementVNode("li", _hoisted_9, [
                                                _createVNode(_component_router_link, { to: "/admin/planner/sync" }, {
                                                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                                                        _createTextVNode(" Обновление базы WebPlanner ")
                                                    ])),
                                                    _: 1
                                                })
                                            ]),
                                            _createElementVNode("li", _hoisted_10, [
                                                _createVNode(_component_router_link, { to: "/admin/planner/sync-properties" }, {
                                                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                                                        _createTextVNode(" Обновление свойств WebPlanner ")
                                                    ])),
                                                    _: 1
                                                })
                                            ]),
                                            _createElementVNode("li", _hoisted_11, [
                                                _createVNode(_component_router_link, { to: "/admin/planner/sync-models" }, {
                                                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                                                        _createTextVNode(" Обновление моделей WebPlanner ")
                                                    ])),
                                                    _: 1
                                                })
                                            ])
                                        ])
                                    ])
                                ])
                            ])
                        ])
                    ])
                ])
            ], 64));
        };
    }
});
