import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue";
const _hoisted_1 = {
    class: "collapse navbar-collapse w-auto ps",
    id: "sidenav-collapse-main"
};
const _hoisted_2 = { class: "overflow-y-auto" };
const _hoisted_3 = { class: "list-none p-1 m-0" };
import { ref, computed, onMounted } from "vue";
import MenuListItem_test from "@/binaplan/components/layouts/sidebar/menu/MenuListItem_test.vue";
import User from "@/module/User";
import lang from "@/plugins/Lang";
import router from "@/binaplan/router";
import auth from "@/api/Auth";
import { useSideMainStore } from "@/store/SideMainStore";
export default /*@__PURE__*/ _defineComponent({
    __name: 'ASideMain_test',
    props: {
        showSidebar: {
            type: Boolean,
            default: false,
        },
    },
    setup(__props) {
        const sideMainStore = useSideMainStore();
        const activePage = computed(() => {
            let page = router.currentRoute.value.name;
            if (typeof router.currentRoute.value.meta !== "undefined") {
                page = router.currentRoute.value.meta.section ?? page;
            }
            return page;
        });
        const userGroups = ref(User.getMe().groups);
        const drawer = ref(false);
        const userEmail = auth.getEmail();
        const props = __props;
        const autoKitchenCollectionLinks = ref(sideMainStore.autoKitchenLinks);
        onMounted(async () => {
            await sideMainStore.requestCollectionLinks();
            autoKitchenCollectionLinks.value = sideMainStore.autoKitchenLinks;
            if (userEmail.length > 0) {
                // user.getMe();
            }
        });
        const accountLinks = [
            { title: "Настройки", page: "/account/settings" },
            { title: lang.get("auth.logout"), page: "/logout" },
        ];
        const switchDrawer = () => {
            drawer.value = !drawer.value;
        };
        const list2 = [
            {
                name: "web-planner",
                path: "/",
                label: "Web Planner",
                icon: "fa-solid fa-rectangle-list",
            },
            {
                name: "properties-groups",
                path: "/properties/groups",
                label: "Группы свойств",
                icon: "fa-solid fa-layer-group",
            },
            {
                name: "properties-models",
                path: "/properties/models",
                label: "Свойства моделей",
                icon: "fa-solid fa-layer-group",
            },
            {
                name: "attributes",
                path: "/attributes",
                label: "Атрибуты",
                icon: "fa-solid fa-layer-group",
            },
            {
                name: "anchors",
                path: "/anchors",
                label: "Якоря",
                icon: "fa-solid fa-anchor",
            },
            {
                name: "params",
                path: "/handbooks/params",
                label: "Параметры",
                icon: "fa-solid fa-sliders",
            },
            {
                name: "autoKitchen",
                label: "Авто-кухни",
                group: ["global-admin", "auto-kitchens"],
                icon: "fa-solid fa-list-check",
                child: [
                    {
                        name: "catalogs",
                        path: "/auto-kitchens/materials",
                        label: "Каталоги",
                        group: ["global-admin", "auto-kitchens"],
                        icon: "fa-solid fa-chevron-right",
                    },
                    {
                        name: "style",
                        path: "/auto-kitchens/style",
                        label: "Стили",
                        group: ["global-admin", "auto-kitchens"],
                        icon: "fa-solid fa-chevron-right",
                    },
                    {
                        name: "collection",
                        label: "Коллекции",
                        group: ["global-admin", "auto-kitchens"],
                        icon: "fa-solid fa-chevron-right",
                        child: [
                            {
                                name: "catalogs",
                                path: "/auto-kitchens/collection/create",
                                label: "Новая коллекция",
                                group: ["global-admin", "auto-kitchens"],
                                icon: "fa-solid fa-plus",
                            },
                        ],
                    },
                ],
            },
            {
                name: "handbooks",
                label: "Справочники",
                group: ["global-admin"],
                icon: "fa-solid fa-list-check",
                child: [
                    {
                        name: "company",
                        path: "/handbooks/fasteners/company",
                        label: "Компании",
                        icon: "fa-solid fa-building",
                    },
                    {
                        name: "attributes",
                        path: "/handbooks/fasteners/attributes",
                        label: "Атрибуты",
                        icon: "fa-solid fa-layer-group",
                    },
                    {
                        name: "matching",
                        path: "/handbooks/fasteners/matching",
                        label: "Сопоставления",
                        icon: "fa-solid fa-diagram-project",
                    },
                    {
                        name: "fastening",
                        path: "/handbooks/fasteners/fastening",
                        label: "Комплекты крепежа",
                        icon: "fa-solid fa-list-check",
                    },
                    {
                        name: "fastening_params",
                        path: "/handbooks/fasteners/fastening_params",
                        label: "Параметры крепежа",
                        icon: "fa-solid fa-list-check",
                    },
                ],
            },
            {
                name: "account",
                path: "/profile/setting",
                label: lang.get("menu.account"),
                icon: "fa-solid fa-user",
            },
            {
                name: "admin",
                label: lang.get("menu.admin_dashboard"),
                group: ["global-admin"],
                icon: "fa-solid fa-user-tie",
                child: [
                    {
                        name: "dashboard",
                        path: "/admin/dashboard",
                        label: "Синхронизация",
                        group: ["global-admin"],
                        icon: "fa-solid fa-sliders",
                    },
                    {
                        name: "catalog",
                        path: "/admin/materials",
                        label: "Каталог материалов",
                        group: ["global-admin"],
                        icon: "fa-solid fa-sliders",
                    },
                    {
                        name: "groups",
                        path: "/admin/marks/groups",
                        label: "Признаки",
                        group: ["global-admin"],
                        icon: "fa-solid fa-sliders",
                    },
                ],
            },
            {
                name: "logout",
                path: "/logout",
                label: lang.get("auth.logout"),
                icon: "fa-solid fa-arrow-right-from-bracket",
            },
        ];
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("aside", {
                class: _normalizeClass(["bp-nav-list sidenav navbar navbar-vertical h-auto navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 ps ps--active-y", __props.showSidebar ? 'active-sidebar' : '']),
                id: "sidenav-main",
                "data-color": "dark"
            }, [
                _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"sidenav-header\"><i class=\"fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none\" aria-hidden=\"true\" id=\"iconSidenav\"></i><a class=\"navbar-brand m-0\" href=\"/\" target=\"_blank\"><img src=\"/assets/images/logo.png\" class=\"navbar-brand-img h-100\" alt=\"main_logo\"><span class=\"brand-title ms-1 font-weight-bold\">Planner 2.0</span></a></div><hr class=\"horizontal dark mt-0\">", 2)),
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("ul", _hoisted_3, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(autoKitchenCollectionLinks.value, (item) => {
                                return (_openBlock(), _createBlock(MenuListItem_test, {
                                    key: item.label,
                                    payload: item,
                                    "user-groups": userGroups.value,
                                    activePage: activePage.value ?? ''
                                }, null, 8, ["payload", "user-groups", "activePage"]));
                            }), 128))
                        ])
                    ])
                ])
            ], 2));
        };
    }
});
