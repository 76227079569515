import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "row" };
const _hoisted_2 = { class: "col-lg-12 mb-4" };
const _hoisted_3 = { class: "card" };
const _hoisted_4 = { class: "card-body" };
const _hoisted_5 = { key: 0 };
const _hoisted_6 = { class: "table align-items-center mb-0" };
const _hoisted_7 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" };
const _hoisted_8 = { key: 1 };
import Lang from "@/plugins/Lang";
import { ref, onMounted } from "vue";
import NomenclatureHandler from "@/api/Nomenclature";
import { notify } from "@kyvg/vue3-notification";
export default /*@__PURE__*/ _defineComponent({
    __name: 'TypeListContainer',
    setup(__props) {
        const lang = ref({
            login: Lang.get("auth.title_signup_page"),
            field_type: Lang.get("nomenclature.table_column_type"),
        });
        const types = ref(null);
        function setTypes() {
            NomenclatureHandler.getTypeList()
                .then((res) => {
                types.value = res.getResponse();
            })
                .catch((err) => {
                notify({
                    title: Lang.get("globals.default_error_title"),
                    type: "error",
                    text: err.getMessage(),
                });
            });
        }
        onMounted(() => {
            setTypes();
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                            (types.value !== null && types.value.length > 0)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                    _createElementVNode("table", _hoisted_6, [
                                        _createElementVNode("thead", null, [
                                            _createElementVNode("tr", null, [
                                                _createElementVNode("th", _hoisted_7, _toDisplayString(lang.value.field_type), 1)
                                            ])
                                        ]),
                                        _createElementVNode("tbody", null, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(types.value, (type) => {
                                                return (_openBlock(), _createElementBlock("tr", {
                                                    key: type.id
                                                }, [
                                                    _createElementVNode("td", null, _toDisplayString(type.name), 1)
                                                ]));
                                            }), 128))
                                        ])
                                    ])
                                ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_8))
                        ])
                    ])
                ])
            ]));
        };
    }
});
