import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "row" };
const _hoisted_2 = { class: "col-sm-12 mt-sm-0 mt-4" };
import UserList from "@/binaplan/components/admin/user/ListContainer.vue";
import TitlePage from "@/binaplan/components/layouts/TitlePageContainer.vue";
import { ref } from "vue";
import Lang from "@/plugins/Lang";
export default /*@__PURE__*/ _defineComponent({
    __name: 'ListView',
    setup(__props) {
        const lang = ref({
            title_page: Lang.get("admin-user.section_title"),
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createVNode(TitlePage, {
                    "back-button": "",
                    "back-url": "",
                    "title-page": lang.value.title_page
                }, null, 8, ["title-page"]),
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createVNode(UserList)
                    ])
                ])
            ], 64));
        };
    }
});
