import LangLibrary from "lang.js";
class Lang {
    defaultLocal = "ru";
    defaultFallback = "en";
    langHandler;
    messages;
    constructor() {
        this.messages = {
            en: {
                "project.title": "binaplan",
            },
        };
        if (typeof window.binaplan !== "undefined") {
            this.messages = window.binaplan.lang;
        }
        this.langHandler = new LangLibrary({
            messages: this.messages,
            locale: this.getLocale(),
            fallback: this.defaultFallback,
        });
    }
    get(key) {
        return this.langHandler.get(key);
    }
    setLocale(locale) {
        localStorage.setItem("lang", locale);
    }
    getLocale() {
        return localStorage.getItem("lang") ?? this.defaultLocal;
    }
    getNameLocale() {
        const code = this.getLocale();
        const activeLanguage = this.getLanguages().find((l) => l.code == code);
        return activeLanguage.name;
    }
    getLanguages() {
        return [
            { name: "Русский", code: "ru" },
            { name: "English", code: "en" },
        ];
    }
}
export default new Lang();
