import * as pako from "pako";
import router from "@/binaplan/router";
import PB from "@/plugins/pb";
class PlannerWSocket {
    host;
    ws;
    wsTimeout;
    wsTrying = false;
    destroying = false;
    wsOpened = false;
    _waitingMessages;
    _messageCallbacks = {};
    _messageRequestId = 0;
    constructor() {
        this.host = "wss://wpar-test.af-softlab.com/builder/";
        this._waitingMessages = [];
    }
    createWebSocket() {
        const interval = setInterval(() => {
            if (typeof router !== "undefined") {
                const auth = router.currentRoute.value.meta.auth ?? false;
                if (auth) {
                    if (!this.wsTrying) {
                        this.wsTrying = true;
                        //setInterval(() => {
                        if (this.ws == null) {
                            this.ws = new WebSocket(this.host);
                            this.ws.onopen = () => this.socketOpen();
                            this.ws.onerror = (e) => {
                                this.wsTrying = false;
                                console.warn("error WS");
                                // this.processing.next(false);
                                // this.serverError.next({ type: DesignerErrorType.Network });
                            };
                            this.ws.binaryType = "arraybuffer";
                            this.ws.onmessage = (message) => this.socketMessage(message);
                            this.ws.onclose = () => {
                                this.wsTrying = false;
                                console.warn("close WS");
                                if (this.wsTimeout) {
                                    window.clearTimeout(this.wsTimeout);
                                    this.wsTimeout = undefined;
                                }
                                this.wsOpened = false;
                                this.ws = undefined;
                                setTimeout(() => {
                                    this.createWebSocket();
                                }, 500);
                            };
                        }
                        //}, 10000);
                    }
                }
                clearInterval(interval);
            }
        }, 100);
    }
    socketOpen() {
        this.wsTrying = false;
        window.clearTimeout(this.wsTimeout);
        this.wsOpened = true;
        console.info("start PLANNER WS");
        setTimeout(() => this.processWaitedMessages(), 10);
    }
    socketMessage(message) {
        if (typeof message.data === "string") {
            const data = JSON.parse(message.data);
            console.log("ANSWER: ", data);
        }
        else {
            const dataEncode = new Uint8Array(message.data, 4);
            console.log("dataEncode", dataEncode);
            const unCompressRequestBody = pako.inflate(dataEncode);
            // const pbHandler = new PB(unCompressRequestBody);
            console.log("unCompressRequestBody", JSON.stringify(unCompressRequestBody));
            const pbHandler = new PB(unCompressRequestBody);
            pbHandler.decode(() => {
                console.log("ANSWER!");
            });
            /*
            const pbHandler = pb.Scene.decode(dataEncode);
      */
            // console.log("pbHandler", pbHandler);
            /*
                  const requestHeader = new Uint8Array(message.data, 0, 8);
      
                  let requestIdString = "";
                  for (let i = 0; i < requestHeader.length; i++) {
                      requestIdString += requestHeader[i];
                  }
      
      
                  const requestId = parseInt(requestIdString);
                  const compressRequestBody = new Uint8Array(message.data, 8);
                  const unCompressRequestBody = pako.inflateRaw(compressRequestBody);
      
      
                  const callback = this._messageCallbacks[requestId];
                  pbHandler.decode(callback);*/
        }
    }
    processWaitedMessages() {
        if (typeof this._waitingMessages !== "undefined") {
            for (let i = 0; i <= this._waitingMessages.length; i++) {
                if (typeof this._waitingMessages[i] !== "undefined") {
                    // this._waitingMessages.splice(i, 1);
                    if (this.sendSocketMessage(this._waitingMessages[i])) {
                        this._waitingMessages[i] = undefined;
                    }
                }
            }
            // this._waitingMessages = [];
        }
    }
    b64_to_utf8(str) {
        return window.atob(str);
    }
    send(method, params) {
        return new Promise((resolve) => {
            this.sendSocketMessage({
                method: method,
                params: params,
            }, resolve);
        });
    }
    utf8AbFromStr(str) {
        const strUtf8 = str;
        const ab = new Uint8Array(strUtf8.length);
        for (let i = 0; i < strUtf8.length; i++) {
            ab[i] = strUtf8.charCodeAt(i);
        }
        return ab;
    }
    strFromUtf8Ab(ab) {
        return decodeURIComponent(escape(String.fromCharCode.apply(null, ab)));
    }
    sendRawData(message, fullLogs = true, type = "in") {
        const enc = new TextEncoder(); // always utf-8
        const DATA = {
            raw: "",
            json: {},
        };
        const aaaa = this.b64_to_utf8(message);
        if (fullLogs)
            console.log("aaaa", aaaa);
        DATA.raw = aaaa;
        const bbbb = this.utf8AbFromStr(aaaa);
        if (fullLogs)
            console.log("bbbb", bbbb);
        const unCompressData = pako.inflate(new Uint8Array(bbbb));
        if (fullLogs)
            console.log("unCompressData", unCompressData);
        const requestData = JSON.parse(this.strFromUtf8Ab(unCompressData));
        DATA.json = requestData;
        //console.log(JSON.stringify(requestData));
        return DATA;
        /*
          if (fullLogs)
            this.sendSocketMessage(
              {
                requestId: 1,
                content: bbbb,
              },
              resolve
            );*/
    }
    sendSocketMessage(message, callback) {
        if (callback && typeof callback !== "undefined") {
            message.requestId = ++this._messageRequestId;
            this._messageCallbacks[message.requestId] = callback;
        }
        if (this.wsOpened &&
            typeof this.ws !== "undefined" &&
            this.ws.readyState >= WebSocket.CLOSING) {
            this.disconnect();
        }
        if (typeof this.ws !== "undefined" && this.wsOpened) {
            console.log("send message", message);
            this.ws.send(message);
            return true;
        }
        else {
            if (typeof this._waitingMessages === "undefined") {
                this._waitingMessages = [];
            }
            this._waitingMessages.push(message.content);
            if (!this.ws) {
                this.createWebSocket();
            }
        }
        return false;
    }
    disconnect() {
        if (this.wsTimeout) {
            window.clearTimeout(this.wsTimeout);
            this.wsTimeout = undefined;
        }
        if (this.ws) {
            this.wsOpened = false;
            // this.ws.onerror = undefined;
            // this.ws.onclose = undefined;
            this.ws.close();
            this.ws = undefined;
            this._messageCallbacks = {};
            this._waitingMessages = [];
        }
    }
}
export default new PlannerWSocket();
