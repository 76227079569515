import CompanyHandler from "@/api/Company";
import { notify } from "@kyvg/vue3-notification";
import Lang from "@/plugins/Lang";
class Company {
    constructor() { }
    async getList() {
        let response = null;
        await CompanyHandler.getList()
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            notify({
                title: Lang.get("globals.default_error_title"),
                type: "error",
                text: err.getMessage(),
            });
            response = err;
        });
        return response;
    }
}
export default new Company();
