import { defineStore } from "pinia";
import { ref } from "vue";
import User from "@/module/User";
import PlannerBackend from "@/module/WebPlanner";
export const useAutoKitchenStore = defineStore("autoKitchenStore", () => {
    const loader = ref(false);
    const catalog = ref([]);
    const distributedCatalog = ref([]);
    const styles = ref({
        data: [
            {
                id: "1000",
                img: "https://primefaces.org/cdn/primevue/images/product/bamboo-watch.jpg",
                price: "65",
                subTitle: "Product Description",
                title: "Bamboo Watch",
                inventoryStatus: "Доступно",
            },
        ],
    });
    const surveyConfigData = ref([
        {
            id: 0,
            title: "Отметьте понравившиеся кухни",
            description: "Можно выбрать несколько моделей.",
            maxItem: 3,
            availableAnswers: [],
        },
        {
            id: 1,
            title: "Какая форма кухни вам подходит?",
            description: undefined,
            maxItem: 1,
            availableAnswers: [],
        },
        {
            id: 2,
            title: "Укажите размер будущей кухни",
            description: undefined,
            availableAnswers: [],
        },
        {
            id: 3,
            title: "Укажите ценовой сегмент будущей кухни",
            description: undefined,
            maxItem: 1,
            availableAnswers: [],
        },
        {
            id: 4,
            title: "Какой ценовой сегмент фурнитуры вам походит?",
            description: undefined,
            maxItem: 1,
            availableAnswers: [],
        },
        {
            id: 5,
            title: "Выберите подходящий тип напольных шкафов",
            description: undefined,
            footnote: "*Увеличивает стоимость погонного метра",
            maxItem: 1,
            availableAnswers: [],
        },
        {
            id: 6,
            title: "Выберите подходящий тип навесных шкафов",
            description: undefined,
            footnote: "*Увеличивает стоимость погонного метра",
            maxItem: 1,
            availableAnswers: [],
        },
        {
            id: 7,
            title: "Выберите высоту навесных шкафов",
            description: undefined,
            footnote: "*Увеличивает стоимость погонного метра",
            //Добавить антресоль
            check: {
                active: false,
                title: "Добавить антресоль*",
            },
            maxItem: 1,
            availableAnswers: [],
        },
        {
            id: 8,
            title: "Выберите глубину антресоли",
            description: undefined,
            // Появляется если в вопросе id:6 выбран items[0]
            check: {
                active: false,
                title: "Установить ручки",
            },
            maxItem: 1,
            availableAnswers: [],
        },
        {
            id: 9,
            title: "Выберите подходящее расположение духовки",
            description: "Техника не входит в расчетную стоимость кухни",
            footnote: "*Увеличивает стоимость погонного метра",
            maxItem: 1,
            availableAnswers: [],
        },
        {
            id: 10,
            title: "Выберите расположение холодильника",
            description: "Техника не входит в расчетную стоимость кухни",
            footnote: "*Увеличивает стоимость погонного метра",
            //Размер кухни указан с учётом холодильника
            check: {
                enabled: false,
                active: true,
                title: "Размер кухни указан с учётом холодильника",
            },
            maxItem: 1,
            availableAnswers: [],
        },
        {
            id: 11,
            title: "Мытье посуды",
            description: "Техника не входит в расчетную стоимость кухни",
            footnote: "*Увеличивает стоимость погонного метра",
            maxItem: 1,
            availableAnswers: [],
        },
        {
            id: 12,
            title: "Выберите тип варочной поверхности",
            description: undefined,
            maxItem: 1,
            availableAnswers: [],
        },
        {
            id: 13,
            title: "Выберите тип вытяжки",
            description: undefined,
            footnote: "*Увеличивает стоимость погонного метра",
            maxItem: 1,
            availableAnswers: [],
        },
    ]);
    const getSurveyConfigData = () => {
        return surveyConfigData.value;
    };
    const getStyles = () => {
        return styles.value.data;
    };
    const addStyle = (style) => {
        styles.value.data.push(style);
    };
    const removeStyle = (style) => {
        styles.value.data.splice(styles.value.data.findIndex((s) => s.id === style.id), 1);
    };
    const getCatalog2 = async () => {
        const userInstanceId = User.getMe().instanceId;
        const list = await PlannerBackend.getCatalogList(userInstanceId).then(async (res) => {
            try {
                return res
                    ?.getResponse()
                    .list.filter((item) => item.name === "Быстрые кухни" || item.name === "ЛайтКухни");
            }
            catch (err) {
                //
            }
        });
        console.log("List", list);
        if (list) {
            const catalog3 = [];
            for (const item of list) {
                catalog3.push({
                    key: item.externalId,
                    data: {
                        catalog: item.name,
                        externalId: item.externalId,
                        name: item.name,
                        shared: item.shared,
                        preview: item.preview,
                    },
                    children: [],
                });
            }
            console.log("CATALOG3", catalog3);
            for (const catalog of catalog3) {
                // const newChildren = await PlannerBackend.getCatalogModels(
                //   catalog.data.externalId.toString(),
                //   folderId
                // ).then((r) => {
                //   if (r == null) return;
                //   console.log("response2", r?.getResponse());
                //   return dataConversion(r?.getResponse());
                // });
            }
        }
    };
    const getCatalog = async () => {
        loader.value = true;
        const userInstanceId = User.getMe().instanceId;
        const list = await PlannerBackend.getCatalogList(userInstanceId).then(async (res) => {
            console.log("response1", res?.getResponse());
            return res
                ?.getResponse()
                .list.filter((item) => item.name === "Быстрые кухни" || item.name === "ЛайтКухни");
        });
        if (list !== null) {
            catalog.value = [];
            for (const i of list) {
                const catalogId = i.externalId === 220 ? "220" : "232";
                // const folderId = i.externalId === 220 ? "204" : "0";
                const folderId = "0";
                const newChildren = await PlannerBackend.getCatalogModels(i.externalId.toString(), folderId).then((r) => {
                    if (r == null)
                        return;
                    console.log("response2", r?.getResponse());
                    return dataConversion(r?.getResponse(), catalogId);
                });
                console.log("test22", newChildren);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                catalog.value.push(newChildren);
            }
            console.log("test", catalog.value);
        }
        console.log(list);
        loader.value = false;
    };
    const dataConversion = (data, catalogId) => {
        if (data.catalog) {
            console.log("yyy", data);
            const result = {
                key: data.externalId,
                data: {
                    catalog: data.catalog.name,
                    catalogId,
                    externalId: data.externalId,
                    name: data.name,
                    shared: data.shared,
                    preview: data.preview,
                },
                children: [],
                distributedCatalog: [],
            };
            // const items = [];
            const testItems = [];
            const children = [];
            // for (const item of data.children) {
            //   if (!item.name.includes("🚫")) {
            //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //     // @ts-ignore
            //     items.push(...dataConversion(item));
            //     // result.items.push(...dataConversion(item));
            //   }
            // }
            /// новый сборщик
            testItems.push(...flattenTree(data, catalogId));
            const aaa = {
                key: 0,
                data: {
                    catalog: "Нераспределенные",
                    externalId: 0,
                    name: "Нераспределенные",
                    preview: "",
                },
                children: [...buildTree(testItems).children],
            };
            result.children.push(aaa);
            result.distributedCatalog.push(...buildTree(testItems, false));
            /// новый сборщик
            // for (const item of items.filter((item) => item.model)) {
            //   const index = children.findIndex((obj) => obj.data.name === item.layer);
            //   if (index !== -1) {
            //     children[index].children.push({
            //       key: item.externalId,
            //       data: {
            //         id: item.id,
            //         catalogId: item.catalogId,
            //         externalId: item.externalId,
            //         name: item.name,
            //         parentId: item.parentId,
            //         preview: item.preview,
            //         size: item.size,
            //         uid: item.uid,
            //       },
            //     });
            //   } else {
            //     children.push({
            //       key: item.externalId,
            //       data: {
            //         name: item.layer,
            //         preview: "",
            //       },
            //       children: [
            //         {
            //           key: item.externalId,
            //           data: {
            //             id: item.id,
            //             catalogId: item.catalogId,
            //             externalId: item.externalId,
            //             name: item.name,
            //             parentId: item.parentId,
            //             preview: item.preview,
            //             size: item.size,
            //             uid: item.uid,
            //           },
            //         },
            //       ],
            //     });
            //   }
            // }
            // console.log("trtrt", children);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // result.children.push(...children);
            return result;
        }
        else if (!data.catalog && !data.model) {
            const result = [];
            if (typeof data.children !== "undefined") {
                for (const item of data.children) {
                    result.push(item);
                }
            }
            return result;
        }
    };
    const flattenTree = (node, catalogId) => {
        const flatArray = [];
        if (!node.name.includes("🚫")) {
            // Общие поля для всех узлов
            flatArray.push({
                id: node.id,
                name: node.name,
                parentId: node.parentId,
                catalogId,
                externalId: node.externalId,
                preview: node.preview,
                // Специфичные поля
                ...(node.children
                    ? { childrenCount: node.children.length }
                    : {
                        properties: node.properties,
                        size: node.size,
                        uid: node.uid,
                    }),
            });
            // Если есть дочерние узлы, обрабатываем их рекурсивно
            if (node.children) {
                node.children.forEach((child) => {
                    flatArray.push(...flattenTree(child, catalogId));
                });
            }
        }
        return flatArray;
    };
    const buildTree = (flatArray, unallocated = true) => {
        const idMapping = {};
        let root = null;
        if (unallocated) {
            // Сначала создаем объекты TreeResultNode для каждого узла
            flatArray.forEach((node) => {
                idMapping[node.id] = {
                    key: node.externalId,
                    data: node,
                };
            });
            flatArray.forEach((node) => {
                const currentNode = idMapping[node.id];
                // Если parentId равен 0, это корневой элемент
                if (node.parentId === 0) {
                    root = currentNode;
                }
                else {
                    // Находим родительский элемент
                    const parentNode = idMapping[node.parentId];
                    if (parentNode) {
                        // Добавляем текущий элемент в массив children родителя
                        parentNode.children = parentNode.children || [];
                        if (!currentNode.data.properties) {
                            parentNode.children.push(currentNode);
                        }
                        else {
                            if (!currentNode.data.properties.length)
                                parentNode.children.push(currentNode);
                        }
                    }
                }
            });
        }
        else {
            flatArray.forEach((node) => {
                if (node.properties && node.properties.length) {
                    idMapping[node.id] = {
                        key: node.externalId,
                        catalogId: node.catalogId,
                        externalId: node.externalId,
                        id: node.id,
                        name: node.name,
                        parentId: node.parentId,
                        preview: node.preview,
                        properties: [],
                        size: node.size,
                        uid: node.uid,
                        // data: node,
                    };
                    const ttt = node.properties[node.properties.length - 1];
                    if (!ttt.valueId) {
                        idMapping[node.id].properties.push(ttt.value);
                    }
                    else {
                        idMapping[node.id].properties.push(ttt.property.values.find((t) => t.id === ttt.valueId.toString()).value);
                    }
                    // for (const i of node.properties) {
                    //   console.log("ytytyt", i);
                    //   if (!i.valueId) {
                    //     idMapping[node.id].properties.push(i.value);
                    //   } else {
                    //     idMapping[node.id].properties.push(
                    //       i.property.values.find(
                    //         (t: any) => t.id === i.valueId.toString()
                    //       ).value
                    //     );
                    //   }
                    // }
                }
            });
            flatArray.forEach((node) => {
                const currentNode = idMapping[node.id];
                if (currentNode) {
                    // Если parentId равен 0, это корневой элемент
                    if (root === null) {
                        console.log("null", currentNode);
                        root = [currentNode];
                    }
                    else {
                        root.push(currentNode);
                    }
                }
            });
        }
        return root;
    };
    return {
        styles,
        loader,
        catalog,
        getCatalog,
        getCatalog2,
        getStyles,
        addStyle,
        removeStyle,
        getSurveyConfigData,
    };
});
