import defaultRequest from "@/api/DefaultRequest";
class Company {
    constructor() { }
    create(params) {
        return defaultRequest.post("companies/add", params);
    }
    getList() {
        return defaultRequest.get("companies");
    }
}
export default new Company();
