import { defineStore } from "pinia";
import AK from "@/module/AutoKitchen";
import lang from "@/plugins/Lang";
export const useSideMainStore = defineStore("sideMainStore", {
    state: () => ({
        autoKitchenLinks: [
            {
                name: "web-planner",
                path: "/",
                label: "Каталоги",
                icon: "fa-solid fa-rectangle-list",
            },
            {
                name: "autoKitchen",
                label: "Авто-кухни",
                group: ["global-admin", "auto-kitchens"],
                icon: "fa-solid fa-list-check",
                child: [
                    {
                        name: "catalogs",
                        path: "/auto-kitchens/materials",
                        label: "Каталоги",
                        group: ["global-admin", "auto-kitchens"],
                        icon: "fa-solid fa-chevron-right",
                    },
                    {
                        name: "style",
                        path: "/auto-kitchens/survey-configurator",
                        label: "Конфигуратор опроса",
                        group: ["global-admin", "auto-kitchens"],
                        icon: "fa-solid fa-chevron-right",
                    },
                    {
                        name: "collection",
                        label: "Коллекции",
                        group: ["global-admin", "auto-kitchens"],
                        icon: "fa-solid fa-chevron-right",
                        child: [
                            {
                                name: "catalogs",
                                path: "/auto-kitchens/collection/create",
                                label: "Новая коллекция",
                                group: ["global-admin", "auto-kitchens"],
                                icon: "fa-solid fa-plus",
                            },
                        ],
                    },
                ],
            },
            {
                name: "handbooks",
                label: "Справочники",
                icon: "fa-solid fa-sitemap",
                child: [
                    {
                        name: "properties-groups",
                        path: "/handbooks/properties/groups",
                        label: "Группы свойств",
                        icon: "fa-solid fa-layer-group",
                    },
                    {
                        name: "properties-models",
                        path: "/handbooks/properties/models",
                        label: "Свойства моделей",
                        icon: "fa-solid fa-layer-group",
                    },
                    {
                        name: "attributes",
                        path: "/handbooks/attributes",
                        label: "Атрибуты",
                        icon: "fa-solid fa-flag",
                    },
                    {
                        name: "anchors",
                        path: "/handbooks/anchors",
                        label: "Якоря",
                        icon: "fa-solid fa-anchor",
                    },
                    {
                        name: "params",
                        path: "/handbooks/params",
                        label: "Параметры",
                        delimiter: true,
                        icon: "fa-solid fa-sliders",
                    },
                    {
                        name: "catalog",
                        path: "/handbooks/materials",
                        label: "Материалы",
                        icon: "fa-solid fa-table-list",
                    },
                    {
                        name: "catalog",
                        path: "/handbooks/materials/properties",
                        label: "Свойства материалов",
                        icon: "fa-solid fa-table-columns",
                    },
                    {
                        name: "groups",
                        path: "/handbooks/marks/groups",
                        label: "Признаки",
                        delimiter: true,
                        icon: "fa-solid fa-thumbtack",
                    },
                    {
                        name: "company",
                        path: "/handbooks/fasteners/company",
                        label: "Компании",
                        icon: "fa-solid fa-users",
                    },
                    {
                        name: "attributes",
                        path: "/handbooks/fasteners/attributes",
                        label: "Атрибуты",
                        icon: "fa-solid fa-layer-group",
                    },
                    {
                        name: "matching",
                        path: "/handbooks/fasteners/matching",
                        label: "Сопоставления",
                        icon: "fa-solid fa-diagram-project",
                    },
                    {
                        name: "fastening",
                        path: "/handbooks/fasteners/fastening",
                        label: "Комплекты крепежа",
                        icon: "fa-solid fa-list-check",
                    },
                    {
                        name: "fastening_params",
                        path: "/handbooks/fasteners/fastening_params",
                        label: "Параметры крепежа",
                        icon: "fa-solid fa-list-check",
                    },
                ],
            },
            /*
            {
              name: "account",
              path: "/profile/setting",
              label: lang.get("menu.account"),
              icon: "fa-solid fa-user",
            },*/
            {
                name: "admin",
                label: lang.get("menu.admin_dashboard"),
                group: ["global-admin"],
                icon: "fa-solid fa-user-tie",
                child: [
                    {
                        name: "dashboard",
                        path: "/admin/dashboard",
                        label: "Синхронизация",
                        group: ["global-admin"],
                        icon: "fa-solid fa-sliders",
                    },
                ],
            },
            {
                name: "logout",
                path: "/logout",
                label: lang.get("auth.logout"),
                icon: "fa-solid fa-arrow-right-from-bracket",
            },
        ],
    }),
    actions: {
        async requestCollectionLinks() {
            await AK.getCollections().then((res) => {
                const collectionsLinks = res?.getResponse();
                const autoKitchen = this.autoKitchenLinks.filter((item) => item.name === "autoKitchen");
                const autoKitchenCollectionLinks = autoKitchen[0].child.filter((child) => child.name === "collection")[0].child;
                autoKitchenCollectionLinks.splice(1, autoKitchenCollectionLinks.length - 1);
                for (const link of collectionsLinks) {
                    autoKitchenCollectionLinks.push({
                        name: link.name,
                        path: `/auto-kitchens/collection/${link.id}`,
                        label: link.name,
                        group: ["global-admin", "auto-kitchens"],
                        icon: "fa-solid fa-plus",
                    });
                }
            });
            console.log(this.autoKitchenLinks);
        },
        async createCollection(data) {
            const res = await AK.addCollection(data);
            await this.requestCollectionLinks();
            return res;
        },
        async delete(id) {
            const res = await AK.delete(id);
            await this.requestCollectionLinks();
            return res;
        },
    },
});
