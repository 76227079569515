import AdminLayout from "@/binaplan/views/admin/AdminLayoutView.vue";
import Dashboard from "@/binaplan/views/admin/pages/DashboardView.vue";
//import CompaniesLayout from "@/binaplan/views/admin/CompaniesLayoutView.vue";
import CompanyList from "@/binaplan/views/admin/pages/companies/ListView.vue";
import CompanyCreate from "@/binaplan/views/admin/pages/companies/CreateView.vue";
//import NomenclatureLayout from "@/binaplan/views/admin/AdminLayoutView.vue"; // use default layout
import NomenclatureTypesList from "@/binaplan/views/admin/pages/nomenclatures/TypeListView.vue";
import NomenclatureTypesCreate from "@/binaplan/views/admin/pages/nomenclatures/TypeCreateView.vue";
import UserList from "@/binaplan/views/admin/pages/users/ListView.vue";
import UserDetail from "@/binaplan/views/admin/pages/users/DetailView.vue";
import TextureList from "@/binaplan/views/admin/pages/textures/ListView.vue";
import TextureCreate from "@/binaplan/views/admin/pages/textures/FormView.vue";
class repository {
    route;
    constructor() {
        this.route = [
            {
                path: "/admin/",
                name: "admin",
                meta: { auth: true, section: "admin", group: ["global-admin"] },
                component: AdminLayout,
                children: [
                    {
                        path: "dashboard",
                        name: "admin-dashboard",
                        meta: { auth: true, section: "admin", group: ["global-admin"] },
                        component: Dashboard,
                    },
                    /*
                    {
                      path: "fix-material",
                      name: "admin-fix-material",
                      meta: { auth: true, section: "admin", group: ["global-admin"] },
                      component: () =>
                        import("@/binaplan/views/admin/pages/_fixBugMaterial.vue"),
                    },*/
                    /*
                    {
                      path: "properties/create",
                      name: "admin-properties-create",
                      meta: { auth: true, section: "admin", group: ["global-admin"] },
                      component: () =>
                        import("@/binaplan/views/admin/pages/properties/FormView.vue"),
                    },*/
                    /*
                    {
                      path: "properties",
                      meta: { auth: true, section: "admin" },
                      component: () =>
                        import("@/binaplan/views/admin/pages/properties/ListView.vue"),
                    },
                    {
                      path: "characteristics",
                      meta: { auth: true, section: "admin" },
                      component: () =>
                        import(
                          "@/binaplan/views/admin/pages/characteristics/ListView.vue"
                        ),
                    },
                    {
                      path: "characteristics/create",
                      meta: { auth: true, section: "admin" },
                      component: () =>
                        import(
                          "@/binaplan/views/admin/pages/characteristics/FormView.vue"
                        ),
                    },
                    {
                      path: "properties/create",
                      meta: { auth: true, section: "admin" },
                      component: () =>
                        import("@/binaplan/views/admin/pages/properties/FormView.vue"),
                    },*/
                    {
                        name: "admin-nomenclature",
                        path: "nomenclature/",
                        meta: { auth: true, section: "admin", group: ["global-admin"] },
                        component: AdminLayout,
                        children: [
                            {
                                name: "admin-nomenclature-types",
                                path: "types",
                                meta: { section: "admin", group: ["global-admin"] },
                                component: NomenclatureTypesList,
                            },
                            {
                                name: "admin-nomenclature-types-create",
                                path: "types/create",
                                meta: { section: "admin", group: ["global-admin"] },
                                component: NomenclatureTypesCreate,
                            },
                        ],
                    },
                    {
                        name: "admin-companies",
                        path: "companies/",
                        meta: { auth: true, section: "admin", group: ["global-admin"] },
                        component: AdminLayout,
                        children: [
                            {
                                name: "admin-companies-page",
                                path: "",
                                meta: { section: "admin", group: ["global-admin"] },
                                component: CompanyList,
                            },
                            {
                                name: "admin-companies-create",
                                path: "create",
                                meta: { section: "admin", group: ["global-admin"] },
                                component: CompanyCreate,
                            },
                        ],
                    },
                    {
                        name: "admin-users",
                        path: "users/",
                        meta: { auth: true, section: "admin", group: ["global-admin"] },
                        component: AdminLayout,
                        children: [
                            {
                                name: "admin-users-page",
                                path: "",
                                meta: { section: "admin", group: ["global-admin"] },
                                component: UserList,
                            },
                            {
                                name: "admin-user",
                                path: ":id",
                                meta: { section: "admin", group: ["global-admin"] },
                                component: UserDetail,
                            },
                        ],
                    },
                    {
                        name: "admin-textures",
                        path: "textures/",
                        meta: { auth: true, section: "admin", group: ["global-admin"] },
                        component: AdminLayout,
                        children: [
                            {
                                name: "admin-textures-page",
                                path: "",
                                meta: { section: "admin", group: ["global-admin"] },
                                component: TextureList,
                            },
                            {
                                name: "admin-texture",
                                path: ":id",
                                meta: { section: "admin", group: ["global-admin"] },
                                component: TextureCreate,
                            },
                        ],
                    },
                    {
                        path: "/admin/planner/instances",
                        name: "admin-planner-instances",
                        meta: { auth: true, section: "admin" },
                        component: () => import("@/binaplan/views/admin/pages/planner/InstanceList.vue"),
                    },
                    {
                        path: "/admin/planner/instances/create",
                        name: "admin-planner-instances-create",
                        meta: { auth: true, section: "admin" },
                        component: () => import("@/binaplan/views/admin/pages/planner/InstanceForm.vue"),
                    },
                    {
                        path: "/admin/planner/sync",
                        name: "admin-planner-instances-sync",
                        meta: { auth: true, section: "admin" },
                        component: () => import("@/binaplan/views/admin/pages/planner/SyncInstanceList.vue"),
                    },
                    {
                        path: "/admin/planner/sync-properties",
                        name: "admin-planner-instances-sync-properties",
                        meta: { auth: true, section: "admin" },
                        component: () => import("@/binaplan/views/admin/pages/planner/SyncPropertiesList.vue"),
                    },
                    {
                        path: "/admin/planner/sync-models",
                        name: "admin-planner-instances-sync-models",
                        meta: { auth: true, section: "admin" },
                        component: () => import("@/binaplan/views/admin/pages/planner/SyncModelList.vue"),
                    },
                ],
            },
        ];
    }
}
export default new repository();
