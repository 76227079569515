import { load } from "protobufjs";
export default class PB {
    constructor(buffer) {
        this.buffer = buffer;
    }
    buffer;
    decode(callback) {
        load("/proto/geometry.proto", (err, root) => {
            if (err)
                throw err;
            if (typeof root === "undefined") {
                return;
            }
            const AwesomeMessage = root.lookupType("Geometry");
            const decoded = AwesomeMessage.decode(this.buffer);
            callback(decoded);
        });
    }
}
