import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "row" };
const _hoisted_2 = { class: "col-lg-6" };
const _hoisted_3 = { class: "mb-3" };
const _hoisted_4 = {
    for: "formFile",
    class: "form-label"
};
const _hoisted_5 = { class: "mb-3" };
const _hoisted_6 = {
    for: "formFile",
    class: "form-label"
};
const _hoisted_7 = { class: "mb-3" };
import Lang from "@/plugins/Lang";
import NomenclatureHandler from "@/api/Nomenclature";
import { ref } from "vue";
import { notify } from "@kyvg/vue3-notification";
export default /*@__PURE__*/ _defineComponent({
    __name: 'TypeCreateContainer',
    setup(__props) {
        const typeName = ref(null);
        const lang = ref({
            field_name: Lang.get("admin-nomenclature.type_field_name"),
            field_parent_select: Lang.get("admin-nomenclature.type_field_parent"),
            btn_create_type: Lang.get("admin-nomenclature.type_button_add"),
        });
        function createType() {
            NomenclatureHandler.createType({
                name: typeName.value,
            })
                .then((res) => {
                notify({
                    title: Lang.get("globals.default_success_title"),
                    type: "success",
                    text: res.getMessage(),
                });
            })
                .catch((err) => {
                notify({
                    title: Lang.get("globals.default_error_title"),
                    type: "error",
                    text: err.getMessage(),
                });
            });
        }
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("label", _hoisted_4, _toDisplayString(lang.value.field_name) + ": ", 1),
                        _withDirectives(_createElementVNode("input", {
                            class: "form-control",
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((typeName).value = $event)),
                            type: "text"
                        }, null, 512), [
                            [_vModelText, typeName.value]
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("label", _hoisted_6, _toDisplayString(lang.value.field_parent_select) + ": ", 1),
                        _cache[2] || (_cache[2] = _createElementVNode("select", {
                            name: "",
                            class: "form-select",
                            id: ""
                        }, null, -1))
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("button", {
                            onClick: _cache[1] || (_cache[1] = ($event) => (createType())),
                            class: "btn btn-info btn-sm"
                        }, _toDisplayString(lang.value.btn_create_type), 1)
                    ])
                ])
            ]));
        };
    }
});
