import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, unref as _unref, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 id" };
const _hoisted_2 = {
    key: 0,
    class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 manager"
};
const _hoisted_3 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 texture-picture" };
const _hoisted_4 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" };
const _hoisted_5 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" };
const _hoisted_6 = {
    key: 1,
    class: "manager"
};
const _hoisted_7 = ["onClick"];
const _hoisted_8 = { class: "id" };
const _hoisted_9 = {
    key: 0,
    class: "manager no-select-layout"
};
const _hoisted_10 = ["onClick"];
const _hoisted_11 = ["onClick"];
const _hoisted_12 = { class: "texture-picture" };
const _hoisted_13 = { key: 1 };
const _hoisted_14 = {
    key: 2,
    class: "base-texture"
};
const _hoisted_15 = { class: "manager" };
const _hoisted_16 = {
    key: 0,
    href: "#"
};
const _hoisted_17 = ["onClick"];
const _hoisted_18 = { key: 1 };
const _hoisted_19 = { colspan: "5" };
import Lang from "@/plugins/Lang";
import Template from "@/service/Template";
import { ref, onMounted, computed, watch } from "vue";
import SurfaceHandler from "@/module/Surface";
import { notify } from "@kyvg/vue3-notification";
export default /*@__PURE__*/ _defineComponent({
    __name: 'TextureList',
    props: {
        decorTextures: { type: Array },
        selectedTextures: Object,
        needEdit: { type: Boolean, default: false },
        needDelete: { type: Boolean, default: false },
        possibilityRemove: { type: String, default: "n" },
        type: { type: String, default: "default" },
        selectedIdTextures: Array,
    },
    emits: [
        "onLoad",
        "onClick",
        "onEdit",
        "onDelete",
        "onUpdateList",
        "onUpdateSort",
    ],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const selectedIdTextures = ref([]);
        const textures = ref(computed(() => {
            sort();
            return props.decorTextures;
        }));
        function changeSelectedList() {
            if (typeof props.selectedTextures !== "undefined") {
                selectedIdTextures.value = [];
                for (let i = 0; i < props.selectedTextures.length; i++) {
                    selectedIdTextures.value.push(props.selectedTextures[i].id);
                }
            }
        }
        const emit = __emit;
        const lang = {
            column_id: Lang.get("surface.texture_column_id"),
            column_picture: Lang.get("surface.texture_column_picture"),
            column_name: Lang.get("surface.texture_column_name"),
            column_layer: Lang.get("nomenclature.column_layer"),
            column_middle_color: Lang.get("surface.texture_column_middle_color"),
            texture_not_list: Lang.get("surface.texture_empty_list"),
        };
        const haveContent = ref((typeof textures.value !== "undefined" &&
            Object.keys(textures).length > 0));
        /**
         * Находим предыдущий элемент
         * @param sort
         * @param type
         */
        function getAdjacentTexture(sort, type = "lower") {
            let prevTexture = {};
            let nextTexture = {};
            if (typeof textures.value !== "undefined") {
                let lower = -1;
                let upper = textures.value.length + 1;
                for (let texture of textures.value) {
                    if (texture.sort < sort && texture.sort > lower) {
                        lower = texture.sort;
                        prevTexture = texture;
                    }
                    if (texture.sort > sort && texture.sort < upper) {
                        upper = texture.sort;
                        nextTexture = texture;
                    }
                }
            }
            return type === "lower" ? prevTexture : nextTexture;
        }
        watch(() => textures.value, sort);
        function sort() {
            if (typeof textures.value !== "undefined") {
                textures.value.sort((a, b) => (a.sort < b.sort ? 1 : -1));
            }
            emit("onUpdateSort", textures.value);
        }
        function sortingUp(selectedTexture) {
            if (typeof textures.value !== "undefined") {
                if (selectedTexture.sort > 1 && textures.value.length > 1) {
                    const prev = getAdjacentTexture(selectedTexture.sort);
                    for (const key in textures.value) {
                        const texture = textures.value[key];
                        if (texture.id === selectedTexture.id) {
                            textures.value[key].sort--;
                        }
                        if (texture.id === prev.id) {
                            textures.value[key].sort++;
                        }
                    }
                    sort();
                }
            }
        }
        function sortingDown(selectedTexture) {
            if (typeof textures.value !== "undefined") {
                if (selectedTexture.sort !== textures.value.length &&
                    textures.value.length > 1) {
                    const next = getAdjacentTexture(selectedTexture.sort, "upper");
                    for (const key in textures.value) {
                        const texture = textures.value[key];
                        if (texture.id === selectedTexture.id) {
                            textures.value[key].sort++;
                        }
                        if (texture.id === next.id) {
                            textures.value[key].sort--;
                        }
                    }
                    sort();
                }
            }
        }
        function action(texture) {
            if (props.type == "modal") {
                if (!selectedIdTextures.value.includes(texture.id)) {
                    selectedIdTextures.value.push(texture.id);
                    emit("onClick", {
                        texture: texture,
                        action: "add",
                    });
                }
                else {
                    for (let i = 0; i < selectedIdTextures.value.length; i++) {
                        if (texture.id == selectedIdTextures.value[i]) {
                            selectedIdTextures.value.splice(i, 1);
                        }
                    }
                    emit("onClick", {
                        texture: texture,
                        action: "delete",
                    });
                }
            }
        }
        function routeEditPage(textureId) {
            return "/handbooks/textures/edit/" + textureId;
        }
        async function deleteTexture(texture, confirmDelete = false) {
            if (props.type == "default") {
                const promises = await SurfaceHandler.deleteTexture(texture.id, confirmDelete);
                if (promises !== null) {
                    if (promises.getType() == "confirm") {
                        let isDelete = confirm(promises.getResponse().data);
                        if (isDelete) {
                            await deleteTexture(texture, true);
                        }
                    }
                    else if (promises.getType() == "success") {
                        notify({
                            title: Lang.get("globals.default_success_title"),
                            type: "success",
                            text: promises.getResponse().data,
                        });
                        emit("onUpdateList");
                    }
                }
            }
            emit("onDelete", texture);
            sort();
        }
        onMounted(() => {
            changeSelectedList();
            sort();
        });
        return (_ctx, _cache) => {
            const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
            const _component_router_link = _resolveComponent("router-link");
            return (_openBlock(), _createElementBlock("table", {
                class: _normalizeClass(["table align-items-center mb-0", __props.type === 'decor' && haveContent.value ? 'texture-list__table' : ''])
            }, [
                _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                        _createElementVNode("th", _hoisted_1, _toDisplayString(lang.column_id), 1),
                        (__props.type === 'decor')
                            ? (_openBlock(), _createElementBlock("th", _hoisted_2, _toDisplayString(lang.column_layer), 1))
                            : _createCommentVNode("", true),
                        _createElementVNode("th", _hoisted_3, _toDisplayString(lang.column_picture), 1),
                        _createElementVNode("th", _hoisted_4, _toDisplayString(lang.column_name), 1),
                        _createElementVNode("th", _hoisted_5, _toDisplayString(lang.column_middle_color), 1),
                        (__props.needEdit || __props.needDelete)
                            ? (_openBlock(), _createElementBlock("th", _hoisted_6))
                            : _createCommentVNode("", true)
                    ])
                ]),
                _createElementVNode("tbody", null, [
                    (haveContent.value)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(textures.value, (texture) => {
                            return (_openBlock(), _createElementBlock("tr", {
                                key: texture.id,
                                onClick: ($event) => (action(texture)),
                                class: _normalizeClass({ active: selectedIdTextures.value.includes(texture.id) })
                            }, [
                                _createElementVNode("td", _hoisted_8, _toDisplayString(texture.id), 1),
                                (__props.type === 'decor')
                                    ? (_openBlock(), _createElementBlock("td", _hoisted_9, [
                                        _createElementVNode("div", {
                                            class: _normalizeClass(["btn-sorting",
                                                texture.sort !== textures.value.length && textures.value.length > 1
                                                    ? 'active'
                                                    : ''
                                            ]),
                                            onClick: ($event) => (sortingDown(texture))
                                        }, [
                                            _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-sort-up" })
                                        ], 10, _hoisted_10),
                                        _createElementVNode("div", null, _toDisplayString(texture.sort), 1),
                                        _createElementVNode("div", {
                                            class: _normalizeClass(["btn-sorting", texture.sort > 1 && textures.value.length > 1 ? 'active' : '']),
                                            onClick: ($event) => (sortingUp(texture))
                                        }, [
                                            _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-sort-down" })
                                        ], 10, _hoisted_11)
                                    ]))
                                    : _createCommentVNode("", true),
                                _createElementVNode("td", _hoisted_12, [
                                    _createElementVNode("div", {
                                        class: "texture-image",
                                        style: _normalizeStyle(_unref(Template).getBackgroundImage(texture.preview))
                                    }, null, 4)
                                ]),
                                _createElementVNode("td", null, [
                                    (__props.type === 'default')
                                        ? (_openBlock(), _createBlock(_component_router_link, {
                                            key: 0,
                                            to: routeEditPage(texture.id)
                                        }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(texture.name), 1)
                                            ]),
                                            _: 2
                                        }, 1032, ["to"]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(texture.name), 1)),
                                    (__props.type === 'decor')
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_14, _cache[0] || (_cache[0] = [
                                            _createElementVNode("b", null, "Базовая текстура", -1)
                                        ])))
                                        : _createCommentVNode("", true)
                                ]),
                                _createElementVNode("td", null, "#" + _toDisplayString(texture.middleHex), 1),
                                _createElementVNode("td", _hoisted_15, [
                                    (__props.needEdit)
                                        ? (_openBlock(), _createElementBlock("a", _hoisted_16, [
                                            _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-pen-to-square" })
                                        ]))
                                        : _createCommentVNode("", true),
                                    (__props.needDelete)
                                        ? (_openBlock(), _createElementBlock("a", {
                                            key: 1,
                                            href: "#",
                                            onClick: ($event) => (deleteTexture(texture))
                                        }, [
                                            _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-trash" })
                                        ], 8, _hoisted_17))
                                        : _createCommentVNode("", true)
                                ])
                            ], 10, _hoisted_7));
                        }), 128))
                        : (_openBlock(), _createElementBlock("tr", _hoisted_18, [
                            _createElementVNode("td", _hoisted_19, _toDisplayString(lang.texture_not_list), 1)
                        ]))
                ])
            ], 2));
        };
    }
});
