import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "row" };
const _hoisted_2 = { class: "row" };
const _hoisted_3 = { class: "col-lg-12" };
const _hoisted_4 = { class: "card" };
const _hoisted_5 = { class: "card-body" };
import CompanyCreate from "@/binaplan/components/admin/company/CreateContainer.vue";
import TitlePage from "@/binaplan/components/layouts/TitlePageContainer.vue";
import Lang from "@/plugins/Lang";
export default /*@__PURE__*/ _defineComponent({
    __name: 'CreateView',
    setup(__props) {
        const lang = {
            title: Lang.get("admin-company.title_add_page"),
            btn_back: Lang.get("admin-company.button_back"),
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createElementVNode("div", _hoisted_1, [
                    _createVNode(TitlePage, {
                        "back-button": lang.btn_back,
                        "back-url": "/admin/companies",
                        "title-page": lang.title
                    }, null, 8, ["back-button", "title-page"])
                ]),
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("div", _hoisted_5, [
                                _createVNode(CompanyCreate)
                            ])
                        ])
                    ])
                ])
            ], 64));
        };
    }
});
