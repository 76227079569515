import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "row" };
const _hoisted_2 = { class: "col-lg-12 mb-4" };
const _hoisted_3 = { class: "card" };
const _hoisted_4 = { class: "card-body" };
const _hoisted_5 = { key: 0 };
const _hoisted_6 = { class: "table align-items-center mb-0" };
const _hoisted_7 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" };
const _hoisted_8 = { key: 1 };
import Lang from "@/plugins/Lang";
import { ref, onMounted } from "vue";
import Company from "@/module/Company";
export default /*@__PURE__*/ _defineComponent({
    __name: 'ListContainer',
    setup(__props) {
        const lang = ref({
            field_company: Lang.get("company.field_name"),
        });
        const companies = ref(null);
        async function setCompanies() {
            const companiesPromises = await Company.getList();
            if (companiesPromises !== null) {
                companies.value = companiesPromises.getResponse();
            }
        }
        onMounted(async () => {
            // await setCompanies();
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                            (companies.value !== null && companies.value.length > 0)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                    _createElementVNode("table", _hoisted_6, [
                                        _createElementVNode("thead", null, [
                                            _createElementVNode("tr", null, [
                                                _createElementVNode("th", _hoisted_7, _toDisplayString(lang.value.field_company), 1)
                                            ])
                                        ]),
                                        _createElementVNode("tbody", null, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(companies.value, (company) => {
                                                return (_openBlock(), _createElementBlock("tr", {
                                                    key: company.id
                                                }, [
                                                    _createElementVNode("td", null, _toDisplayString(company.name), 1)
                                                ]));
                                            }), 128))
                                        ])
                                    ])
                                ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_8))
                        ])
                    ])
                ])
            ]));
        };
    }
});
