// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class ApiResponse {
    type;
    message;
    response;
    constructor(type, response, errorMessage) {
        this.type = type;
        this.response = response;
        this.message = errorMessage ?? "";
    }
    getType() {
        return this.type;
    }
    getResponse() {
        return this.response;
    }
    getMessage() {
        return this.message;
    }
}
