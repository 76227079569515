import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "row" };
const _hoisted_2 = { class: "col-lg-12 mb-4" };
const _hoisted_3 = { class: "card" };
const _hoisted_4 = { class: "card-body" };
const _hoisted_5 = { key: 0 };
const _hoisted_6 = { class: "table align-items-center mb-0" };
const _hoisted_7 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder" };
const _hoisted_8 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder" };
const _hoisted_9 = { class: "text-uppercase text-secondary text-xxs font-weight-bolder" };
const _hoisted_10 = { class: "created_at" };
const _hoisted_11 = { class: "manager" };
const _hoisted_12 = { key: 1 };
import Lang from "@/plugins/Lang";
import { ref, onMounted } from "vue";
import UserHandler from "@/api/User";
import { notify } from "@kyvg/vue3-notification";
export default /*@__PURE__*/ _defineComponent({
    __name: 'ListContainer',
    setup(__props) {
        const lang = ref({
            login: Lang.get("auth.title_signup_page"),
            field_name: Lang.get("user.field_name"),
            field_email: Lang.get("user.field_email"),
            field_date_create: Lang.get("user.date_create"),
            field_date_update: Lang.get("user.date_update"),
        });
        const users = ref(null);
        function routeDetailPage(id) {
            return "/admin/users/" + id;
        }
        function setUsers() {
            UserHandler.getList()
                .then((res) => {
                users.value = res.getResponse();
            })
                .catch((err) => {
                notify({
                    title: Lang.get("globals.default_error_title"),
                    type: "error",
                    text: err.getMessage(),
                });
            });
        }
        onMounted(() => {
            setUsers();
        });
        return (_ctx, _cache) => {
            const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
            const _component_router_link = _resolveComponent("router-link");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                            (users.value !== null && users.value.length > 0)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                    _createElementVNode("table", _hoisted_6, [
                                        _createElementVNode("thead", null, [
                                            _createElementVNode("tr", null, [
                                                _createElementVNode("th", _hoisted_7, _toDisplayString(lang.value.field_email), 1),
                                                _createElementVNode("th", _hoisted_8, _toDisplayString(lang.value.field_name), 1),
                                                _createElementVNode("th", _hoisted_9, _toDisplayString(lang.value.field_date_create), 1),
                                                _cache[0] || (_cache[0] = _createElementVNode("th", { class: "manager" }, null, -1))
                                            ])
                                        ]),
                                        _createElementVNode("tbody", null, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(users.value, (user) => {
                                                return (_openBlock(), _createElementBlock("tr", {
                                                    key: user.id
                                                }, [
                                                    _createElementVNode("td", null, _toDisplayString(user.email), 1),
                                                    _createElementVNode("td", null, _toDisplayString(user.name), 1),
                                                    _createElementVNode("td", _hoisted_10, _toDisplayString(user.createdAt), 1),
                                                    _createElementVNode("td", _hoisted_11, [
                                                        _createVNode(_component_router_link, {
                                                            to: routeDetailPage(user.id)
                                                        }, {
                                                            default: _withCtx(() => [
                                                                _createVNode(_component_font_awesome_icon, {
                                                                    icon: "fa-pen-to-square",
                                                                    class: "text-secondary"
                                                                })
                                                            ]),
                                                            _: 2
                                                        }, 1032, ["to"])
                                                    ])
                                                ]));
                                            }), 128))
                                        ])
                                    ])
                                ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_12))
                        ])
                    ])
                ])
            ]));
        };
    }
});
