import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = { class: "nav-link-title nav-link-text ms-1" };
const _hoisted_3 = { class: "list-none py-0 pl-1 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out" };
const _hoisted_4 = { key: 0 };
const _hoisted_5 = { class: "flex align-items-center cursor-pointer pl-4 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple" };
const _hoisted_6 = { class: "nav-link-title nav-link-text ms-1" };
const _hoisted_7 = { class: "list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out" };
const _hoisted_8 = { class: "nav-link-title nav-link-text ms-1" };
const _hoisted_9 = { key: 1 };
const _hoisted_10 = { class: "nav-link-title nav-link-text ms-1" };
const _hoisted_11 = {
    key: 2,
    style: { "margin": "4px 0", "border-bottom": "1px solid rgb(221 221 221)" }
};
const _hoisted_12 = { key: 1 };
const _hoisted_13 = { class: "nav-link-title nav-link-text ms-1" };
export default /*@__PURE__*/ _defineComponent({
    __name: 'MenuListItem_test',
    props: {
        payload: {
            type: Object,
            default: () => { },
        },
        userGroups: {
            type: Array,
            default: () => [],
        },
        activePage: {
            type: String,
        },
    },
    setup(__props) {
        function isShow() {
            const pageGroup = typeof props.payload.group !== "undefined" ? props.payload.group : [];
            let access = false;
            if (pageGroup.length > 0) {
                pageGroup.forEach((item) => {
                    if (props.userGroups.includes(item)) {
                        access = true;
                    }
                });
            }
            else
                access = true;
            return access;
        }
        const props = __props;
        return (_ctx, _cache) => {
            const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
            const _component_router_link = _resolveComponent("router-link");
            const _directive_ripple = _resolveDirective("ripple");
            const _directive_styleclass = _resolveDirective("styleclass");
            return (isShow())
                ? (_openBlock(), _createElementBlock("li", {
                    key: 0,
                    class: _normalizeClass(["nav-item", { active: props.payload.name === props.activePage }])
                }, [
                    (props.payload.child)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            _withDirectives((_openBlock(), _createElementBlock("div", {
                                class: _normalizeClass([{ active: props.payload.name === props.activePage }, "flex align-items-center cursor-pointer p-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"])
                            }, [
                                _createElementVNode("div", null, [
                                    _createVNode(_component_font_awesome_icon, {
                                        icon: props.payload.icon,
                                        style: { "margin-right": "5px" }
                                    }, null, 8, ["icon"]),
                                    _createElementVNode("span", _hoisted_2, _toDisplayString(props.payload.label), 1)
                                ]),
                                _createVNode(_component_font_awesome_icon, {
                                    class: "ml-1",
                                    icon: "fa-solid fa-chevron-down"
                                })
                            ], 2)), [
                                [_directive_ripple],
                                [_directive_styleclass, {
                                        selector: '@next',
                                        enterClass: 'hidden',
                                        enterActiveClass: 'slidedown',
                                        leaveToClass: 'hidden',
                                        leaveActiveClass: 'slideup',
                                    }]
                            ]),
                            _createElementVNode("ul", _hoisted_3, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.payload.child, (child) => {
                                    return (_openBlock(), _createElementBlock("li", {
                                        key: child.path
                                    }, [
                                        (child.child)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                                _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
                                                    _createElementVNode("div", null, [
                                                        _createVNode(_component_font_awesome_icon, {
                                                            icon: child.icon,
                                                            style: { "margin-right": "5px" }
                                                        }, null, 8, ["icon"]),
                                                        _createElementVNode("span", _hoisted_6, _toDisplayString(child.label), 1)
                                                    ]),
                                                    _createVNode(_component_font_awesome_icon, {
                                                        class: "ml-1",
                                                        icon: "fa-solid fa-chevron-down"
                                                    })
                                                ])), [
                                                    [_directive_ripple],
                                                    [_directive_styleclass, {
                                                            selector: '@next',
                                                            enterClass: 'hidden',
                                                            enterActiveClass: 'slidedown',
                                                            leaveToClass: 'hidden',
                                                            leaveActiveClass: 'slideup',
                                                        }]
                                                ]),
                                                _createElementVNode("ul", _hoisted_7, [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(child.child, (subChild) => {
                                                        return _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                                                            key: subChild.label,
                                                            class: "flex align-items-center cursor-pointer pl-4 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple",
                                                            to: subChild.path
                                                        }, {
                                                            default: _withCtx(() => [
                                                                _createVNode(_component_font_awesome_icon, {
                                                                    icon: subChild.icon,
                                                                    style: { "margin-right": "5px" }
                                                                }, null, 8, ["icon"]),
                                                                _createElementVNode("span", _hoisted_8, _toDisplayString(subChild.label), 1)
                                                            ]),
                                                            _: 2
                                                        }, 1032, ["to"])), [
                                                            [_directive_ripple]
                                                        ]);
                                                    }), 128))
                                                ])
                                            ]))
                                            : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                                _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                                                    class: "flex subitem align-items-center cursor-pointer pl-4 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple",
                                                    to: child.path
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createVNode(_component_font_awesome_icon, {
                                                            icon: child.icon,
                                                            style: { "margin-right": "5px" }
                                                        }, null, 8, ["icon"]),
                                                        _createElementVNode("span", _hoisted_10, _toDisplayString(child.label), 1)
                                                    ]),
                                                    _: 2
                                                }, 1032, ["to"])), [
                                                    [_directive_ripple]
                                                ])
                                            ])),
                                        (child.delimiter)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_11))
                                            : _createCommentVNode("", true)
                                    ]));
                                }), 128))
                            ])
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                                to: props.payload.path,
                                class: "flex align-items-center cursor-pointer p-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_font_awesome_icon, {
                                        icon: props.payload.icon,
                                        style: { "margin-right": "5px" }
                                    }, null, 8, ["icon"]),
                                    _createElementVNode("span", _hoisted_13, _toDisplayString(props.payload.label), 1)
                                ]),
                                _: 1
                            }, 8, ["to"])), [
                                [_directive_ripple]
                            ])
                        ]))
                ], 2))
                : _createCommentVNode("", true);
        };
    }
});
