import DefaultRequest from "@/api/DefaultRequest";
class User {
    constructor() { }
    getMe() {
        return DefaultRequest.get("users/me");
    }
    getList() {
        return DefaultRequest.get("users");
    }
    getAccessGroupList() {
        return DefaultRequest.get("access-groups");
    }
    getUser(userId) {
        return DefaultRequest.get("users/" + userId);
    }
    updateUser(userId, params) {
        params._method = "PATCH";
        params.companyId = parseInt(params.companyId ?? 0);
        return DefaultRequest.post("users/" + userId, params);
    }
}
export default new User();
