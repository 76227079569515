import { $vfm } from "vue-final-modal";
import { ref } from "vue";
import User from "@/module/User";
import PlannerBackend from "@/module/WebPlanner";
export default class Planner {
    static lastId = 0;
    static queueIntervalHandle = 0;
    static queueCount = ref(0);
    static startObserve(count = 0) {
        Planner.queueCount.value = count;
        if (Planner.queueCount.value > 0 && Planner.queueIntervalHandle === 0) {
            Planner.queueIntervalHandle = setInterval(() => {
                // get query
                PlannerBackend.getQueueState().then((r) => {
                    if (r !== null && r.getType() === "success") {
                        const body = r.getResponse();
                        Planner.queueCount.value = body.items;
                        if (body.items < 1) {
                            clearInterval(Planner.queueIntervalHandle);
                            Planner.queueIntervalHandle = 0;
                        }
                    }
                });
            }, 3000);
        }
    }
    static preview(texture, full = 2) {
        texture = texture ?? "";
        let host = User.getMe().instanceHost != null
            ? User.getMe().instanceHost + "previews/"
            : "https://wpar-test.af-softlab.com/previews/";
        if (texture.length === 0 || texture === "#") {
            if (full == 4) {
                return "background-image: url('" + (host + "/default.jpg") + "')";
            }
            else {
                return "border: 1px solid rgb(255 129 129);";
            }
        }
        if (texture.substring(0, 1) === "#") {
            return "background-color: " + texture;
        }
        else {
            switch (full) {
                default:
                case 1:
                    host =
                        User.getMe().instanceHost != null
                            ? User.getMe().instanceHost + "textures/"
                            : "https://wpar-test.af-softlab.com/textures/";
                    break;
                case 2:
                    host =
                        User.getMe().instanceHost != null
                            ? User.getMe().instanceHost + "thumbnails/"
                            : "https://wpar-test.af-softlab.com/thumbnails/";
                    break;
                case 3:
                    host =
                        User.getMe().instanceHost != null
                            ? User.getMe().instanceHost + "bumpthumbs/"
                            : "https://wpar-test.af-softlab.com/bumpthumbs/";
                    break;
                case 4:
                    host =
                        User.getMe().instanceHost != null
                            ? User.getMe().instanceHost + "previews/"
                            : "https://wpar-test.af-softlab.com/bumpthumbs/";
                    break;
            }
            return ("background-image: url('" +
                (host + texture.substring(0, 2) + "/" + texture) +
                "')");
        }
    }
    static searchField(row, column, values) {
        const field = {
            key: -1,
            value: {},
        };
        if (values.length > 0) {
            values.forEach((value, index) => {
                if (parseInt(value.column.sort) === parseInt(column.sort) &&
                    parseInt(value.row.sort) === parseInt(row.sort)) {
                    field.key = index;
                    field.value = value;
                }
            });
        }
        return field;
    }
    /**
     * Отображаем заданное модальное окно
     * @param name
     * @param lastId
     */
    static showModal(name, lastId = 0) {
        $vfm.show(name).then((r) => function () {
            //
        });
        this.lastId = lastId;
    }
    static closeModal(name = "") {
        $vfm.hide(name.length > 0 ? name : "");
    }
}
