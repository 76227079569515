import HandbookHandler from "@/api/Handbook";
import Lang from "@/plugins/Lang";
import { notify } from "@kyvg/vue3-notification";
import Module from "@/module/Module";
class Surface extends Module {
    async createSurface(params) {
        let response = null;
        await HandbookHandler.createSurface(params)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async createDecor(params) {
        let response = null;
        await HandbookHandler.createDecor(params)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async updateDecor(decorId, params) {
        let response = null;
        await HandbookHandler.updateDecor(decorId, params)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async createTexture(params) {
        let response = null;
        await HandbookHandler.createTexture(params)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getTextureList(decorId = "0") {
        let response = null;
        await HandbookHandler.getTextures(decorId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getTexture(textureId = "0") {
        let response = null;
        await HandbookHandler.getTexture(textureId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getDecorList() {
        let response = null;
        await HandbookHandler.getDecors()
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getDecor(decorId) {
        let response = null;
        await HandbookHandler.getDecor(decorId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async deleteDecor(decorId) {
        let response = null;
        await HandbookHandler.deleteDecor(decorId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async createFinish(params) {
        let response = null;
        await HandbookHandler.createFinish(params)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async updateFinish(finishId, params) {
        let response = null;
        await HandbookHandler.updateFinish(finishId, params)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async createDeepmap(params) {
        let response = null;
        await HandbookHandler.createDeepmap(params)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getDeepmapList(finishId = "0") {
        let response = null;
        await HandbookHandler.getDeepmaps(finishId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getFinishList() {
        let response = null;
        await HandbookHandler.getFinishes()
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    _cacheSurfaceList = null;
    async getSurfaceList(useCache = false) {
        let response = null;
        if (this._cacheSurfaceList === null) {
            await HandbookHandler.getSurfaces()
                .then((res) => {
                response = res;
            })
                .catch((err) => {
                notify({
                    title: Lang.get("globals.default_error_title"),
                    type: "error",
                    text: err.getMessage(),
                });
                response = err;
            });
            if (useCache) {
                this._cacheSurfaceList = response;
            }
        }
        else {
            response = this._cacheSurfaceList;
        }
        return response;
    }
    async getSurface(surfaceId) {
        let response = null;
        await HandbookHandler.getSurface(surfaceId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getFinish(finishId) {
        let response = null;
        await HandbookHandler.getFinish(finishId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async deleteSurface(surfaceId) {
        let response = null;
        await HandbookHandler.deleteSurface(surfaceId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async deleteFinish(finishId) {
        let response = null;
        await HandbookHandler.deleteFinish(finishId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async deleteTexture(textureId, confirmDelete = false) {
        let response = null;
        await HandbookHandler.deleteTexture(textureId, confirmDelete)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async deleteDeepmap(deepmapId, confirmDelete = false) {
        let response = null;
        await HandbookHandler.deleteDeepmap(deepmapId, confirmDelete)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
}
export default new Surface();
