import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "row" };
const _hoisted_2 = { class: "col-lg-6" };
const _hoisted_3 = { class: "mb-3" };
const _hoisted_4 = {
    for: "formFile",
    class: "form-label"
};
const _hoisted_5 = { class: "mb-3" };
import Lang from "@/plugins/Lang";
import CompanyHandler from "@/api/Company";
import { ref } from "vue";
import { notify } from "@kyvg/vue3-notification";
export default /*@__PURE__*/ _defineComponent({
    __name: 'CreateContainer',
    setup(__props) {
        const companyName = ref(null);
        const lang = ref({
            login: Lang.get("auth.title_signup_page"),
            field_company: Lang.get("company.field_name"),
            btn_create_company: Lang.get("company.button_create"),
        });
        function createCompany() {
            CompanyHandler.create({
                name: companyName.value,
            })
                .then((res) => {
                notify({
                    title: Lang.get("globals.default_success_title"),
                    type: "success",
                    text: res.getMessage(),
                });
                // redirect
            })
                .catch((err) => {
                notify({
                    title: Lang.get("globals.default_error_title"),
                    type: "error",
                    text: err.getMessage(),
                });
            });
        }
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("label", _hoisted_4, _toDisplayString(lang.value.field_company) + ": ", 1),
                        _withDirectives(_createElementVNode("input", {
                            class: "form-control",
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((companyName).value = $event)),
                            type: "text"
                        }, null, 512), [
                            [_vModelText, companyName.value]
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("button", {
                            onClick: _cache[1] || (_cache[1] = ($event) => (createCompany())),
                            class: "btn btn-info btn-sm"
                        }, _toDisplayString(lang.value.btn_create_company), 1)
                    ])
                ])
            ]));
        };
    }
});
