import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "container-fluid h-full py-4"
};
const _hoisted_2 = { key: 2 };
const _hoisted_3 = { class: "main-content mt-0" };
const _hoisted_4 = { class: "page-header min-vh-80" };
const _hoisted_5 = { class: "container" };
const _hoisted_6 = { class: "row" };
const _hoisted_7 = { class: "col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto" };
const _hoisted_8 = {
    key: 3,
    class: "scene h-100 w-100 position-absolute"
};
import MainNavbar from "@/binaplan/components/layouts/navbar/MainNavbar.vue";
import FullPageFooter from "@/binaplan/components/layouts/footer/FullPageFooter.vue";
import ASideTest from "@/binaplan/components/layouts/sidebar/ASideMain_test.vue";
import router from "@/binaplan/router";
import { computed, ref, watch } from "vue";
import User from "@/module/User";
import QueueList from "@/binaplan/components/planner/plugins/QueueList.vue";
import Planner from "@/service/Planner";
import { useAutoKitchenStore } from "@/store/AutoKitchenStore";
export default /*@__PURE__*/ _defineComponent({
    __name: 'App',
    setup(__props) {
        // layouts
        // import MainHeader from "@/binaplan/components/layouts/header/MainHeader.vue";
        const sidebarReload = ref(0);
        const autoKitchenStore = useAutoKitchenStore();
        autoKitchenStore.getCatalog();
        autoKitchenStore.getCatalog2();
        const waitLoadingUserParams = setInterval(function () {
            if (User.getMe().id > 0) {
                sidebarReload.value++;
                clearInterval(waitLoadingUserParams);
            }
        }, 100);
        const isScene = ref(false);
        const isLoginPage = computed(() => {
            const path = router.currentRoute.value.path;
            return !(path != "/login" && path != "/signup" && path != "/404");
        });
        const pageLayout = ref();
        watch(() => router.currentRoute.value, () => {
            const minifySidebar = router.currentRoute.value.meta.minifySidebar;
            if (typeof minifySidebar !== "undefined" && minifySidebar) {
                pageLayout.value.classList.add("minify-sidebar");
            }
            else {
                if (pageLayout.value.classList.contains("minify-sidebar")) {
                    pageLayout.value.classList.remove("minify-sidebar");
                }
            }
        });
        const isShowSidebar = ref(false);
        function showSidebar() {
            isShowSidebar.value = !isShowSidebar.value;
        }
        return (_ctx, _cache) => {
            const _component_router_view = _resolveComponent("router-view");
            const _component_notifications = _resolveComponent("notifications");
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createElementVNode("div", {
                    class: "page-layout",
                    ref_key: "pageLayout",
                    ref: pageLayout
                }, [
                    (!isLoginPage.value)
                        ? (_openBlock(), _createBlock(ASideTest, {
                            "show-sidebar": isShowSidebar.value,
                            key: sidebarReload.value
                        }, null, 8, ["show-sidebar"]))
                        : _createCommentVNode("", true),
                    (!isLoginPage.value)
                        ? (_openBlock(), _createElementBlock("main", {
                            key: 1,
                            class: _normalizeClass([{
                                    'max-height-vh-100': isScene.value,
                                    'vh-100  ps ps--active-y': isScene.value,
                                    'active-sidebar': isShowSidebar.value,
                                }, "main-content vh-100 position-relative border-radius-lg"])
                        }, [
                            _createVNode(MainNavbar, { onOnShowSidebar: showSidebar }),
                            (!isLoginPage.value && !isScene.value)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                    _createVNode(_component_router_view)
                                ]))
                                : _createCommentVNode("", true)
                        ], 2))
                        : (_openBlock(), _createElementBlock("main", _hoisted_2, [
                            _createElementVNode("div", _hoisted_3, [
                                _createElementVNode("section", null, [
                                    _createElementVNode("div", _hoisted_4, [
                                        _createElementVNode("div", _hoisted_5, [
                                            _createElementVNode("div", _hoisted_6, [
                                                _createElementVNode("div", _hoisted_7, [
                                                    _createVNode(_component_router_view)
                                                ]),
                                                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-md-6" }, [
                                                    _createElementVNode("div", { class: "oblique position-absolute top-0 h-100 d-md-block d-none me-n8" }, [
                                                        _createElementVNode("div", {
                                                            class: "oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6",
                                                            style: { "background-image": "url('/assets/images/curved-images/background-form-2.jpg')" }
                                                        })
                                                    ])
                                                ], -1))
                                            ])
                                        ])
                                    ])
                                ]),
                                _createVNode(FullPageFooter)
                            ])
                        ])),
                    (!isLoginPage.value && isScene.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createVNode(_component_router_view)
                        ]))
                        : _createCommentVNode("", true)
                ], 512),
                _createVNode(_component_notifications),
                (_openBlock(), _createBlock(QueueList, {
                    key: _unref(Planner).queueCount
                }))
            ], 64));
        };
    }
});
