import UserHandler from "@/api/User";
import Module from "@/module/Module";
class User extends Module {
    async getById(userId) {
        let response = null;
        await UserHandler.getUser(userId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getAccessGroups() {
        let response = null;
        await UserHandler.getAccessGroupList()
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    getMe() {
        const me = sessionStorage.getItem("me");
        return me !== null
            ? JSON.parse(me)
            : {
                instanceHost: null,
            };
    }
}
export default new User();
