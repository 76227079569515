<template>
  <footer class="footer py-4">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 mb-4 mx-auto text-center">
          <a
            target="_blank"
            href="#"
            class="text-secondary me-xl-5 me-3 mb-sm-0 mb-2"
          >
            Temp link
          </a>
          <a
            target="_blank"
            href="#"
            class="text-secondary me-xl-5 me-3 mb-sm-0 mb-2"
          >
            About Us
          </a>
          <a
            target="_blank"
            href="#"
            class="text-secondary me-xl-5 me-3 mb-sm-0 mb-2"
          >
            Team
          </a>
          <a
            target="_blank"
            href="#"
            class="text-secondary me-xl-5 me-3 mb-sm-0 mb-2"
          >
            Temp link
          </a>
          <a
            target="_blank"
            href="#"
            class="text-secondary me-xl-5 me-3 mb-sm-0 mb-2"
          >
            Temp link
          </a>
          <a
            target="_blank"
            href="#"
            class="text-secondary me-xl-5 me-3 mb-sm-0 mb-2"
          >
            Temp link
          </a>
        </div>
        <div class="col-lg-8 mx-auto text-center mb-4 mt-2"></div>
      </div>
      <div class="row">
        <div class="col-8 mx-auto text-center mt-1">
          <p class="mb-0 text-secondary">Copyright © 2022</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts"></script>

<style lang="scss"></style>
