import Module from "@/module/Module";
import Lang from "@/plugins/Lang";
import { notify } from "@kyvg/vue3-notification";
import AutoKitchen from "@/api/AutoKitchen";
import User from "@/module/User";
class Company extends Module {
    checkProd() {
        if (sessionStorage.getItem("prod-confirm") === "1") {
            return true;
        }
        else {
            const confirmAction = (User.getMe().instanceProd &&
                confirm("Вы используете продуктивную версию. Вы действительно хотите продолжить? ")) ||
                !User.getMe().instanceProd;
            if (confirmAction) {
                sessionStorage.setItem("prod-confirm", "1");
                return true;
            }
            else
                return false;
        }
    }
    async addCollection(data) {
        let response = null;
        await AutoKitchen.addCollection(data)
            .then((res) => {
            response = res;
            notify({
                title: Lang.get("globals.default_success_title"),
                type: "success",
                text: "",
            });
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getCollections() {
        let response = null;
        await AutoKitchen.getCollections()
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getCollection(id) {
        let response = null;
        await AutoKitchen.getCollection(id)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async updateCompany(id, data) {
        let response = null;
        await AutoKitchen.updateCollection(id, data)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async delete(id) {
        let response = null;
        if (this.checkProd()) {
            await AutoKitchen.delete(id)
                .then((res) => {
                response = res;
                notify({
                    title: Lang.get("globals.default_success_title"),
                    type: "success",
                    text: "",
                });
            })
                .catch((err) => {
                response = this.notifyError(err);
            });
        }
        return response;
    }
}
export default new Company();
