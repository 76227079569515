import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "row" };
import TitlePage from "@/binaplan/components/layouts/TitlePageContainer.vue";
import Lang from "@/plugins/Lang";
export default /*@__PURE__*/ _defineComponent({
    __name: 'FormView',
    setup(__props) {
        const lang = {
            title: Lang.get("admin-surface.textures_link_add"),
            btn_back: Lang.get("admin-surface.textures_btn_back"),
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createElementVNode("div", _hoisted_1, [
                    _createVNode(TitlePage, {
                        "back-button": lang.btn_back,
                        "back-url": "/admin/textures",
                        "title-page": lang.title
                    }, null, 8, ["back-button", "title-page"])
                ]),
                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "row mt-4" }, [
                    _createElementVNode("div", { class: "col-lg-12" }, [
                        _createElementVNode("div", { class: "card" }, [
                            _createElementVNode("div", { class: "card-body" })
                        ])
                    ])
                ], -1))
            ], 64));
        };
    }
});
