import { createRouter, createWebHistory } from "vue-router";
import Auth from "@/api/Auth";
import User from "@/module/User";
import mainRoutes from "./groups/main";
import adminRoutes from "./groups/admin";
let routes = [];
// merge all routes
routes = routes.concat(mainRoutes.route, adminRoutes.route);
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach((to, from, next) => {
    const absolute = to.matched.some((record) => record.meta.absolutePage);
    if (typeof absolute !== "undefined" && absolute) {
        document.body.classList.add("absolute-page");
    }
    else {
        document.body.classList.remove("absolute-page");
    }
    const needAuth = to.matched.some((record) => record.meta.auth);
    let access = false;
    const userGroups = User.getMe().groups;
    const pageGroup = to.meta.group;
    if (typeof to.name === "undefined") {
        console.log(to);
        next("/404");
        return;
    }
    if (typeof to.meta.group !== "undefined" && pageGroup.length > 0) {
        if (typeof userGroups !== "undefined") {
            pageGroup.forEach((item) => {
                if (userGroups.includes(item)) {
                    access = true;
                }
            });
        }
    }
    else
        access = true;
    if (!access) {
        console.log("test1");
        next("/404");
        return;
    }
    const loginPage = "/login";
    const signupPage = "/signup";
    /**
     * Если авторизован, то должен быть токен
     * @type boolean
     */
    const isAuthorized = Auth.isAuthorized();
    if (to.path == "/logout") {
        Auth.logout().then(() => {
            localStorage.removeItem("token");
            localStorage.removeItem("email");
            sessionStorage.removeItem("me");
            window.location.reload();
        });
        return next("/");
    }
    if (to.path !== loginPage && to.path !== signupPage) {
        sessionStorage.setItem("backUrl", to.path);
    }
    if (isAuthorized) {
        if (to.path === loginPage || to.path === signupPage) {
            return next(sessionStorage.getItem("backUrl") ?? "/");
        }
    }
    else if (needAuth) {
        return next(loginPage);
    }
    next();
});
export default router;
