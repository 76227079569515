import defaultRequest from "@/api/DefaultRequest";
class Nomenclature {
    constructor() { }
    createNomenclature(params) {
        return defaultRequest.post("nomenclatures/add", params);
    }
    deleteNomenclature(surfaceId) {
        const params = { _method: "DELETE" };
        return defaultRequest.post("nomenclatures/" + surfaceId, params);
    }
    updateNomenclature(nomenclatureId, params) {
        const _params = params;
        _params._method = "PATCH";
        return defaultRequest.post("nomenclatures/" + nomenclatureId, params);
    }
    getNomenclature(nomenclatureId) {
        return defaultRequest.get("nomenclatures/" + nomenclatureId);
    }
    getList(categoryId = 0, groupId = 0) {
        let params = categoryId > 0 || groupId > 0 ? "?" : "";
        if (categoryId > 0) {
            params += "categoryId=" + categoryId;
        }
        if (groupId > 0) {
            params += (params.length > 1 ? "&" : "") + "groupId=" + groupId;
        }
        return defaultRequest.get("nomenclatures" + params);
    }
    getTypeList() {
        return defaultRequest.get("nomenclatures/types");
    }
    createType(params) {
        return defaultRequest.post("nomenclatures/types/add", params);
    }
    createCategoryKind(parentId, params) {
        const parent = parentId > 0 ? parentId + "/" : "";
        return defaultRequest.post("nomenclatures/kinds/" + parent + "add", params);
    }
    getKindList(parentId) {
        return defaultRequest.get("nomenclatures/kinds/" + parentId);
    }
}
export default new Nomenclature();
